import { cosplayIcon } from "../../../assets/icons";
import RenderTranslatedText from "../../../components/RenderTranslatedText";
import { animeShowsConfig } from "../constants";

export default function AnimeSelection({ handleAnimeSelect = () => {} }) {
  return (
    <div>
      <div className="w-[56px] h-[56px] bg-[#3DC2FF] rounded-full flex items-center justify-center">
        <img src={cosplayIcon} />
      </div>
      <p className="text-[#686868] text-[14px] mt-1">
        <RenderTranslatedText i8nKey={"community_manga_anime"} />
      </p>
      <p className="font-semibold text-[16px] text-[#414853] mt-5">
        <RenderTranslatedText i8nKey={"community_recommended_fav_card_title"} />
      </p>
      <p className="text-[12px] text-[#555E6C] ">
        <RenderTranslatedText
          i8nKey={"community_recommended_fav_card_subtitle"}
        />
      </p>
      <div className="w-full grid grid-cols-2 gap-5 items-center justify-center mt-5">
        {animeShowsConfig.map(({ cover, titleKey, key }, i) => {
          return (
            <div
              key={i}
              className="w-full flex items-center flex-col gap-3"
              onClick={() => handleAnimeSelect(key)}
            >
              <img src={cover} className="object-fill aspect-square w-full" />
              <p className="font-semibold text-[14px] text-[#555E6C]">
                <RenderTranslatedText i8nKey={titleKey} />
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
