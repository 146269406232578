import { useNavigate } from "react-router-dom";
import { logo } from "../../assets/brand";
import { backArrowIcon } from "../../assets/icons";
import { AMinus, PPlus } from "../../assets/typePlaceholders";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import ShareSection from "../../components/ShareSection";
import useResetScroll from "../../utils/useResetScroll";

export default function PictureBookNoFriendsFallback() {
  useResetScroll();
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <div className="max-w-[450px] mx-auto flex flex-col items-center gap-10">
      <div className="flex items-center gap-5 mt-10 w-[90%] mx-auto">
        <img
          className="w-10 h-10 cursor-pointer"
          src={backArrowIcon}
          onClick={handleBack}
        />
        <p className="text-[20px] font-semibold">
          <RenderTranslatedText
            i8nKey={"picturebook_no_friends_fallback_heading"}
          />
        </p>
      </div>
      <div
        className="w-[80%] h-[400px] flex flex-col justify-center items-center bg-[#F8F3FB] rounded-[16px] "
        style={{
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          lineHeight: "26px",
        }}
      >
        <img src={PPlus} className="w-[100px]" alt="" />
        <p className="text-center text-[14px] mt-10">
          <RenderTranslatedText
            i8nKey={"picturebook_no_friends_fallback_message"}
          />
        </p>
      </div>
      <div className="w-[85%]">
        <ShareSection
          name={localStorage.getItem("USER_NAME")}
          shareLink={`${
            window.location.origin
          }?referrer-id=${localStorage.getItem(
            "USER_ID"
          )}&openExternalBrowser=1`}
          mainColor={localStorage.getItem("USER_QR_COLOR")}
          showQR
          customShareTitle={
            "次のMBTI！生年月日入力のみ。1秒で自分の可能性を拓く｜1秒友達相性診断サービス「native. for Friends」"
          }
        />
      </div>
      <div className="flex flex-col items-center mt-10">
        <img alt="" src={logo} style={{ zIndex: 1, width: "150px" }} />
      </div>
      <p className="text-center text-[8px] text-[#A1A1AA] mb-10">
        © 2024 native.K.K All Rights Reserved.
      </p>
    </div>
  );
}
