import { useLocation, useNavigate } from "react-router-dom";
import {
  assignmentBlack,
  assignmentGrey,
  bookIcon,
  clinicalNotesBlack,
  clinicalNotesGrey,
  communityBlack,
  communityGrey,
  diagnosisBlack,
  diagnosisGrey,
  earthIcon,
} from "../../assets/icons";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const hideNavBlacklist = ["/characters/create"];

export default function Layout({ children }) {
  const {
    t,
    i18n: { language, changeLanguage },
  } = useTranslation();
  const [curLang, setCurLang] = useState(language);
  const isMobile = window.innerWidth < 450;
  const userId = localStorage.getItem("USER_ID");
  const isUserAuthenticated =
    userId && userId?.length > 0 && userId !== "undefined";
  const navigate = useNavigate();
  const location = useLocation();

  const shouldNavHide = hideNavBlacklist.some((path) =>
    location.pathname.includes(path)
  );

  const bottomNavigationConfig = [
    {
      path: "/self-matching",
      name: t("bottom_nav_diagnose"),
      icon: assignmentGrey,
      activeIcon: assignmentBlack,
      customClass: "self-diagnosis",
    },
    {
      path: "/characters",
      name: t("bottom_nav_fav"),
      icon: diagnosisGrey,
      activeIcon: diagnosisBlack,
    },
    {
      path: "/community",
      name: t("bottom_nav_community"),
      icon: communityGrey,
      activeIcon: communityBlack,
    },
    {
      path: "/native-card",
      name: t("bottom_nav_self_matching"),
      icon: clinicalNotesGrey,
      activeIcon: clinicalNotesBlack,
    },
  ];

  const handleLanguageSwitch = (lang) => {
    setCurLang(lang);
    changeLanguage(lang);
  };

  const handlePictureBook = () => navigate("/picture-book");
  const handleSocialGraph = () => navigate("/social-graph");

  return (
    <div className="relative">
      <div
        onClick={handlePictureBook}
        className="w-[50px] h-[50px] rounded-full bg-white fixed right-4 top-4  flex flex-col items-center justify-center z-50 picturebook-icon"
        style={{
          border: "3px solid #FCC500",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <img src={bookIcon} className="w-6" />
      </div>

      <div
        onClick={handleSocialGraph}
        className="w-[50px] h-[50px] rounded-full bg-white fixed right-4 top-20 flex flex-col items-center justify-center z-50 socialgraph-icon"
        style={{
          border: "3px solid #FCC500",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <img src={earthIcon} className="w-6" />
      </div>
      <div className="flex items-center px-3 pt-4 pb-2 gap-2 justify-center">
        <form
          className="flex items-center gap-2"
          onChange={(e) => handleLanguageSwitch(e.target.value)}
        >
          <input
            id="en-radio"
            type="radio"
            name="English"
            value="en"
            checked={curLang === "en"}
          />
          <label htmlFor="en-radio">English</label>
          <input
            id="ja-radio"
            type="radio"
            name="日本語"
            value="ja"
            checked={curLang === "ja"}
          />
          <label htmlFor="ja-radio">日本語</label>
        </form>
      </div>
      <div style={{ paddingBottom: isMobile ? "50px" : "0px" }}>{children}</div>
      {isMobile && isUserAuthenticated && !shouldNavHide ? (
        <div
          className=" w-full fixed bottom-0 bg-white flex flex-row justify-between items-center shadow-2xl rounded-t-2xl px-6 z-50"
          style={{
            boxShadow: "0px 0px 12px -2px rgba(0,0,0,0.75)",
            height: "72px",
            zIndex: 99999,
          }}
        >
          {bottomNavigationConfig?.map(
            ({ activeIcon, icon, name, path, customClass }) => {
              const isActive = location.pathname === path;
              return (
                <div
                  className={
                    `flex flex-col gap-1 items-center w-[80px] ` + customClass
                  }
                  onClick={() => navigate(path)}
                >
                  <img className="w-7 h-7" src={isActive ? activeIcon : icon} />
                  <p
                    className="font-semibold"
                    style={{
                      color: isActive ? "#000" : "#8B8B8B",
                      transition: "0.2s ease",
                      fontSize: language === "en" ? "10px" : "12px",
                    }}
                  >
                    {name}
                  </p>
                </div>
              );
            }
          )}
        </div>
      ) : null}
    </div>
  );
}
