import { ChatBubbleTriangle } from "../../assets";
import { useEffect, useState } from "react";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ShareSection from "../../components/ShareSection";
import { logo } from "../../assets/brand";
import { ConstructionWorker } from "../../assets/illustrations";
import { useNavigate } from "react-router-dom";
import useResetScroll from "../../utils/useResetScroll";
import { crownIcon } from "../../assets/icons";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import { useTranslation } from "react-i18next";
import { createMatchedPageTourConfig } from "../../utils/joyrideTour";
import JoyRideTour from "../../components/JoyRideTour";

const MatchedCard = ({
  hexColor,
  name,
  bubbleText,
  sideText,
  type,
  score,
  otherUserId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userId = localStorage.getItem("USER_ID");
  const handleCompatibilityCheck = () => {
    navigate(`/compatibility/${userId}/${otherUserId}`);
  };
  return (
    <div className="mt-10">
      <div
        className="p-3 w-full rounded-[16px] relative overflow-hidden"
        style={{
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <div
          style={{ background: hexColor, opacity: 0.15 }}
          className="w-[660px] h-[708px] absolute top-0 left-0 z-0"
        />
        <div className="z-10 relative">
          <div>
            <p
              className="text-center font-semibold text-[14px]"
              style={{ color: hexColor }}
            >
              {t("matched_page_card_title", { name })}
            </p>
          </div>
          <div
            className="h-[1px] w-full mt-2 mb-3"
            style={{ background: hexColor }}
          />
          <div className="w-full flex flex-row justify-between">
            <div className="bg-white rounded-[16px] w-[45%] p-2 relative">
              <p style={{ color: hexColor }} className="text-[10px]">
                {bubbleText}
              </p>
              <div className="absolute bottom-[-17px] right-10">
                <img
                  src={ChatBubbleTriangle}
                  alt=""
                  className="w-[40px] h-[40px]"
                />
              </div>
            </div>
            <p style={{ color: hexColor }} className="text-[10px] w-[45%]">
              {sideText}
            </p>
          </div>
          <div className="flex flex-row items-end justify-center gap-2 mt-2">
            <h1
              className="font-extrabold text-[28px]"
              style={{ color: hexColor }}
            >
              {type}
            </h1>
            {type && (
              <img
                alt=""
                src={`https://api.be-native.life/api/friends/charimg/${type}`}
                className="w-[100px] h-[100px]"
                style={{ zIndex: 1 }}
              />
            )}
            <div className="w-[80px]">
              <CircularProgressbarWithChildren
                value={Number(score) > 100 ? 100 : Number(score)}
                strokeWidth={14}
                styles={buildStyles({
                  pathColor: hexColor,
                  trailColor: "#E5E5E5",
                  pathTransitionDuration: 0.5,
                })}
              >
                <div
                  style={{ fontSize: 12 }}
                  className="bg-white h-[75%] w-[75%] rounded-full flex flex-col items-center justify-center"
                >
                  <p className="text-center text-[#FF8D8D] text-[22px] font-extrabold">
                    {score}
                    <span className="text-black text-[14px]">%</span>
                  </p>
                </div>
              </CircularProgressbarWithChildren>
            </div>
          </div>
        </div>
      </div>
      <div className="h-4" />
      {score === "??" ? (
        <button
          onClick={handleCompatibilityCheck}
          className=" border-none w-[100%] mx-auto min-h-[56px] flex items-center justify-center bg-[#FF8D8D] rounded-[20px]"
          style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
        >
          <p className="text-white text-[16px] font-semibold">
            {t("matched_page_check_compatibility_CTA", { name })}
          </p>
        </button>
      ) : (
        <button
          onClick={handleCompatibilityCheck}
          className=" border-none w-[100%] mx-auto min-h-[56px] flex items-center justify-center bg-[#E5E5E5] rounded-[20px]"
          style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
        >
          <p className="text-black text-[16px] font-semibold">
            <RenderTranslatedText
              i8nKey={"matched_page_view_results_again_CTA"}
            />
          </p>
        </button>
      )}
    </div>
  );
};

const IS_MATCHED_PAGE_TOUR_COMPLETED = "IS_MATCHED_PAGE_TOUR_COMPLETED";

export default function Matched() {
  const {
    i18n: { language },
  } = useTranslation();
  const navigate = useNavigate();
  useResetScroll();
  const [matchedData, setMatchedData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showFullList, setShowFullList] = useState(false);
  const [hideFullListButton, setHideFullListButton] = useState(false);

  const [showTour, setShowTour] = useState(
    localStorage.getItem(IS_MATCHED_PAGE_TOUR_COMPLETED) !== "true"
  );

  const fetchData = async () => {
    setIsLoading(true);
    const apiResponse = await fetch(
      ` https://api.be-native.life/api/users/related/${localStorage.getItem(
        "USER_ID"
      )}?lang=${language}`
    );
    const data = await apiResponse.json();
    const dataArray = await Promise.all(
      data?.map(async ({ user_id, input_name, dob }) => {
        const apiResScore = await fetch(
          ` https://api.be-native.life/api/users/pair_score/${localStorage.getItem(
            "USER_ID"
          )}/${user_id}?lang=${language}`
        );
        const scoreData = await apiResScore.json();
        const apiResInfo = await fetch(
          ` https://api.be-native.life/api/friends/friends_data/${`${
            dob?.split("-")?.[0]
          }`}/${`${dob?.split("-")?.[1]}`}/${`${
            dob?.split("-")?.[2]
          }`}?lang=${language}`
        );
        const infoData = await apiResInfo.json();
        return { user_id, input_name, dob, score: scoreData?.score, infoData };
      })
    );
    setMatchedData(dataArray.reverse());
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [language]);

  const handleShowFullList = () => {
    setShowFullList(true);
    const timer = setTimeout(() => {
      setHideFullListButton(true);
    }, 2000);
    return () => clearTimeout(timer);
  };

  const userId = localStorage.getItem("USER_ID");
  const shareLink = `${window.location.origin}/native-card?referrer-id=${userId}&openExternalBrowser=1 `;
  const userName = localStorage.getItem("USER_NAME");

  const handleSelfCompatibilityPage = () => {
    navigate("/self-matching");
  };

  const handleRankingPage = () => navigate("/ranking");

  const handleTourEnd = () => {
    setShowTour(false);
    localStorage.setItem(IS_MATCHED_PAGE_TOUR_COMPLETED, "true");
  };

  return isLoading ? (
    <div className="min-h-[100svh] w-full flex flex-col items-center justify-center gap-2">
      <p className="text-[24px] font-bold text-neutral-800">
        <RenderTranslatedText i8nKey={"loading_text"} />
      </p>
    </div>
  ) : (
    <div className="p-5 bg-white w-full min-h-[100svh]">
      {showTour ? (
        <JoyRideTour
          config={createMatchedPageTourConfig(language)}
          onTourEnd={handleTourEnd}
          onCloseClick={handleTourEnd}
        />
      ) : null}
      <div className="max-w-[450px] mx-auto">
        <div className="w-[95%] mx-auto">
          <div className="flex items-center justify-between mt-5">
            <p className="font-bold text-[18px] text-black">
              <RenderTranslatedText i8nKey={"matched_page_title"} />
            </p>
          </div>
          {matchedData?.length > 0 ? (
            <button
              className="flex flex-row items-center gap-3 bg-[#FCC500] px-5 py-1 rounded-xl mt-2"
              style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
              onClick={handleRankingPage}
            >
              <img className="w-5 h-5" src={crownIcon} />
              <p className="text-[14px] font-bold text-white">
                <RenderTranslatedText
                  i8nKey={"matched_page_check_ranking_CTA"}
                />
              </p>
            </button>
          ) : null}
          {matchedData?.length > 0 ? (
            <>
              {matchedData
                ?.slice(0, 5)
                ?.map(
                  ({
                    user_id,
                    infoData: { hex_color, param30, param9, param7 },
                    input_name,
                    score,
                    dob,
                  }) => {
                    return (
                      <MatchedCard
                        key={user_id}
                        hexColor={hex_color}
                        name={input_name}
                        bubbleText={param30}
                        sideText={param9}
                        type={param7}
                        score={score}
                        dob={dob}
                        otherUserId={user_id}
                      />
                    );
                  }
                )}
              <div className="overflow-hidden pb-1 px-1">
                <div
                  style={{
                    marginBottom: showFullList
                      ? "0px"
                      : `-${(matchedData?.length - 5) * 350}px`,
                    transition: "2s ease",
                  }}
                >
                  {matchedData
                    ?.slice(5)
                    ?.map(
                      ({
                        user_id,
                        infoData: { hex_color, param30, param9, param7 },
                        input_name,
                        score,
                        dob,
                      }) => {
                        return (
                          <MatchedCard
                            key={user_id}
                            hexColor={hex_color}
                            name={input_name}
                            bubbleText={param30}
                            sideText={param9}
                            type={param7}
                            score={score}
                            dob={dob}
                            otherUserId={user_id}
                          />
                        );
                      }
                    )}
                </div>
              </div>
            </>
          ) : (
            <div
              className="mt-10 w-full h-[400px] flex flex-col justify-center items-center bg-[#F8F3FB] rounded-[16px] "
              style={{
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                lineHeight: "26px",
              }}
            >
              <img
                src={ConstructionWorker}
                className="w-[200px] h-[200px]"
                alt=""
              />
              <p className="text-center text-[14px]">
                <RenderTranslatedText
                  i8nKey={"matched_page_fallback_message"}
                />
              </p>
            </div>
          )}
        </div>
        {!hideFullListButton && matchedData?.length > 5 ? (
          <div className="flex flex-col items-center w-full">
            <button
              className=" mt-7 text-[18px] font-bold text-white bg-[#FCC500] rounded-3xl py-3 px-5 w-[85%] mx-auto"
              style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
              onClick={handleShowFullList}
            >
              <RenderTranslatedText i8nKey={"matched_page_see_more_CTA"} />
            </button>
          </div>
        ) : null}
        <div className="h-10" />
        <ShareSection shareLink={shareLink} showQR name={userName} />
        <button
          onClick={handleSelfCompatibilityPage}
          className="mt-5 border-none w-[85%] mx-auto h-[70px] flex items-center justify-center bg-[#EC736E] rounded-[20px] gap-3"
          style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
        >
          <p className="text-white text-[14px] font-semibold">
            <RenderTranslatedText i8nKey={"self_matching_CTA"} />
          </p>
        </button>
        <div className="flex flex-col items-center mt-10 mb-1">
          <img alt="" src={logo} style={{ zIndex: 1 }} className="w-[150px]" />
        </div>
        <p className="text-center text-[8px] text-[#A1A1AA]">
          © 2024 native.K.K All Rights Reserved.
        </p>
      </div>
    </div>
  );
}
