import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
  useLocation,
  createRoutesFromChildren,
  matchRoutes
} from "react-router-dom";
import "./global.css";
import i18n from "i18next";
import {  initReactI18next } from "react-i18next";
import enJSON from './locale/en.json'
import jaJSON from './locale/ja.json'
import ReactGA from 'react-ga4';

import Welcome from "./pages/welcome";
import Login from "./pages/login";
import IssueCard from "./pages/issuecard";
import NativeCard from "./pages/nativecard";
import Compatibility from "./pages/compatibility";
import Matched from "./pages/matched";
import Reset from "./pages/reset";
import TermsAndConditions from "./pages/terms";
import PrivacyPolicy from "./pages/privacy";
import Tokushoho from "./pages/tokushoho";
import SelfMatching from "./pages/selfmatching";
import Ranking from "./pages/ranking";
import Layout from "./components/Layout";
import PictureBook from "./pages/picturebook";
import SocialGraph from "./pages/socialgraph";

import * as Sentry from "@sentry/react";
import PictureBookNoFriendsFallback from "./pages/picturebook/picturebookNoFriendsFallback";
import Community from "./pages/community";
import Characters from "./pages/characters";
import CharactersCreate from "./pages/characterscreate";
import CharactersSingle from "./pages/characterssingle";
import CharactersSeeAll from "./pages/charactersseeall";

const RedirectIfLoggedIn = ({ children }) => {
  const userID = localStorage.getItem('USER_ID')
  return !!userID ? <Navigate to='/native-card' /> : children
}

const OtaSplashScreen = () => {
  return <div className="fixed top-0 left-0 w-[100vw] h-[100svh] flex flex-col items-center justify-center z-50" style={{
    background: "linear-gradient(168.08deg, #07B9CE -1.07%, #3969E7 53.24%, #7D2AE7 102.38%)",
    zIndex: 9999999
  }}>
    <p className="font-bold text-[120px] text-center text-white">Ōta</p>
  </div>
}

const AppWrapper = () => {
  const location = useLocation()
  const [showSplash, setShoaSplash] = React.useState(true)
  ReactGA.initialize('G-HQVKZF94MW');
  React.useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
    })
  }, [location])
  React.useEffect(() => {
    setTimeout(() => {
      setShoaSplash(false)
    }, 2500);
  }, [])
  return <div>{showSplash ? <OtaSplashScreen /> : null}<Outlet /></div>
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <AppWrapper />,
    children: [
      {
        path: "/",
        element: <RedirectIfLoggedIn>
          <Welcome />
        </RedirectIfLoggedIn>,
      },
      {
        path: "/login",
        element: <RedirectIfLoggedIn>
          <Login />
        </RedirectIfLoggedIn>,
      },
      {
        path: "/issue-card",
        element: <IssueCard />,
      },
      {
        path: "/native-card",
        element: <Layout><NativeCard /></Layout>,
      },
      {
        path: "/native-card/:name/:year/:month/:day",
        element: <Layout><NativeCard isCustom /></Layout>,
      },
      {
        path: "/compatibility/:userId/:referrerId",
        element: <Layout><Compatibility /></Layout>,
      },
      {
        path: "/matched",
        element: <Layout><Matched /></Layout>,
      },
      {
        path: '/reset',
        element: <Reset />
      },
      {
        path: '/terms-and-conditions',
        element: <Layout><TermsAndConditions /></Layout>
      },
      {
        path: '/privacy-policy',
        element: <Layout> <PrivacyPolicy /></Layout>
      },
      {
        path: '/tokushoho',
        element: <Layout> <Tokushoho /></Layout>
      },
      {
        path: "/self-matching",
        element: <Layout><SelfMatching /></Layout>
      },
      {
        path: "/compatibility/:userId/:name/:year/:month/:day",
        element: <Layout><Compatibility isSingleSelfMatchingType /></Layout>,
      },
      {
        path: "/characters/compatibility/:userId/:name/:year/:month/:day/:charId",
        element: <Layout><Compatibility isSingleSelfMatchingType isCharacterType /></Layout>,
      },
      {
        path: "/compatibility/:name/:year/:month/:day/:name2/:year2/:month2/:day2",
        element: <Layout><Compatibility isDoubleSelfMatchingType /></Layout>,
      },
      {
        path: "/ranking",
        element: <Layout><Ranking /></Layout>,
      },
      {
        path: "/picture-book",
        element: <Layout><PictureBook /></Layout>,
      },
      {
        path: "/social-graph",
        element: <Layout><SocialGraph /></Layout>,
      },
      {
        path: '/picture-book-no-friends',
        element: <Layout><PictureBookNoFriendsFallback /></Layout>,
      },
      {
        path: '/community',
        element: <Layout><Community /></Layout>,
      },
      {
        path: '/community/:key',
        element: <Layout><Community /></Layout>,
      },
      {
        path: '/community/:key/create',
        element: <Layout><Community isCreate /></Layout>,
      },
      {
        path: '/community/:key/:threadId',
        element: <Layout><Community isThreadView /></Layout>,
      },
      {
        path: '/characters',
        element: <Layout><Characters /></Layout>,
      },
      {
        path: '/characters/create',
        element: <Layout><CharactersCreate /></Layout>,
      },
      {
        path: '/characters/:charId',
        element: <Layout><CharactersSingle /></Layout>,
      },
      {
        path: '/characters/:categoryKey/:categoryTitle',
        element: <Layout><CharactersSeeAll /></Layout>,
      },
      {
        path: "*",
        element: <Welcome />,
      },
    ]
  }
]);

var userLang = navigator.language || navigator.userLanguage; 


i18n.use(initReactI18next).init({
  resources: {
    en: { ...enJSON },
    ja: { ...jaJSON },
  },
  lng: userLang?.includes('en')?'en':'ja',
  fallbackLng: "en",
});

Sentry.init({
  dsn: "https://9a5cce4089bc48f776a3f2cc9e625622@o4507786450108416.ingest.us.sentry.io/4507898011910144",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: process.env.DISABLE_SENTRY !== 'true'
});

ReactDOM.createRoot(document.getElementById("root"), {
  onUncaughtError: Sentry.reactErrorHandler((error, errorInfo) => {
    console.warn('Uncaught error', error, errorInfo.componentStack);
  }),
  onCaughtError: Sentry.reactErrorHandler(),
  onRecoverableError: Sentry.reactErrorHandler(),
}).render(
  <RouterProvider router={router} />
);