import React from "react";
import { facebookIcon, lineIcon, shareIcon, xIcon } from "../../assets/social";
import { logoJPG } from "../../assets/brand";
import { QRCode } from "react-qrcode-logo";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import {
  FacebookShareButton,
  LineShareButton,
  TwitterShareButton,
} from "react-share";
import { WhiteCard } from "../PageCommons";
import RenderTranslatedText from "../RenderTranslatedText";
import { useTranslation } from "react-i18next";

export default function ShareSection({
  mainColor,
  name,
  shareLink,
  isCompatibility = false,
  noShadow = false,
  showQR = false,
  customShareTitle,
}) {
  const {t}=useTranslation()
  //@ts-ignore
  const shareLinkText = shareLink;
  const isDesktop = window.innerWidth >= 1100;

  const handleCopyLink = () => {
    if (isDesktop) {
      alert(t("copied_to_clipboard_text"));
    }
    navigator.clipboard.writeText(shareLinkText);
  };

  const shareTitle =
    customShareTitle || name
      ? t("share_section_sns_title_text_named", { name })
      : t("share_section_sns_title_text_unnamed");

  const qrCodeColor = localStorage.getItem("USER_QR_COLOR");

  return (
    <WhiteCard noShadow={noShadow}>
      {noShadow ? null : <div className="h-5" />}
      {showQR ? (
        <p className="text-black text-center font-semibold text-[16px]">
          <RenderTranslatedText i8nKey={"share_section_heading"} />
        </p>
      ) : null}
      {showQR ? (
        <div className="flex flex-col items-center mt-5 mb-8 w-[60%] mx-auto">
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={shareLinkText}
            fgColor={qrCodeColor ?? mainColor}
            logoImage={logoJPG.src}
            logoHeight={40}
            logoWidth={90}
            logoOpacity={1}
            removeQrCodeBehindLogo
            qrStyle="fluid"
            logoPadding={10}
            logoPaddingStyle="square"
          />
        </div>
      ) : null}
      {showQR ? (
        <p className="text-black text-center font-semibold text-[14px]">
          <RenderTranslatedText
            i8nKey={"share_card_compatibility_test_banner_subtitle"}
          />
        </p>
      ) : (
        <p className="text-black text-center font-semibold text-[16px]">
          {isCompatibility
            ? t("compatibility_page_share_rectangle_text")
            : "診断結果をSNSでシェア、相性診断"}
        </p>
      )}
      <div className="mt-2 flex w-full flex-row justify-center gap-3 items-center mb-2">
        <TwitterShareButton
          title={shareTitle}
          url={shareLinkText}
          hashtags={t("share_section_sns_hashtags")?.split(",")}
          related={["@benative14"]}
        >
          <div
            className="w-[55px] aspect-square flex items-center justify-center rounded-[12px]"
            style={{ border: "0.6px solid #000" }}
          >
            <img alt="" src={xIcon} style={{ zIndex: 1, width: "30px" }} />
          </div>
        </TwitterShareButton>
        <LineShareButton title={shareTitle} url={shareLinkText}>
          <div
            className="w-[55px] aspect-square flex items-center justify-center rounded-[12px]"
            style={{ border: "0.6px solid #000" }}
          >
            <img alt="" src={lineIcon} style={{ zIndex: 1, width: "30px" }} />
          </div>
        </LineShareButton>
        <FacebookShareButton url={shareLinkText} title={shareTitle}>
          <div
            className="w-[55px] aspect-square flex items-center justify-center rounded-[12px]"
            style={{ border: "0.6px solid #000" }}
          >
            <img
              alt=""
              src={facebookIcon}
              style={{ zIndex: 1, width: "30px" }}
            />
          </div>
        </FacebookShareButton>
        {isDesktop ? null : (
          <Tooltip id="copied-to-clipboard-tooltip">
            <RenderTranslatedText i8nKey={"copied_to_clipboard_text"} />
          </Tooltip>
        )}
        <div
          data-tooltip-id="copied-to-clipboard-tooltip"
          className="w-[55px] aspect-square flex items-center justify-center rounded-[12px] cursor-pointer"
          style={{ border: "0.6px solid #000" }}
          onClick={handleCopyLink}
        >
          <img alt="" src={shareIcon} style={{ zIndex: 1, width: "30px" }} />
        </div>
      </div>
    </WhiteCard>
  );
}
