import { useState } from "react";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import AnimeSelection from "./components/AnimeSelection";
import { useNavigate, useParams } from "react-router-dom";
import SelectedAnime from "./components/SelectedAnime";

export default function Community({ isCreate, isThreadView }) {
  const { key } = useParams();
  const navigate = useNavigate();

  const handleAnimeSelect = (anime) => {
    navigate(`/community/${anime}`);
  };

  return (
    <div
      className=" bg-white w-full min-h-[100svh]"
      style={{
        background:
          "linear-gradient(90deg, rgba(190, 147, 197, 0.3) 0%, rgba(123, 198, 204, 0.3) 138.87%)",
      }}
    >
      <div className="p-5 w-full h-[60px] bg-white flex items-center">
        <div className="max-w-[450px] mx-auto w-full">
          <p className="font-semibold text-[20px] text-[#414853]">
            <RenderTranslatedText i8nKey={"community_page_title"} />
          </p>
        </div>
      </div>

      <div className="p-5 max-w-[450px] mx-auto">
        {!key ? (
          <AnimeSelection handleAnimeSelect={handleAnimeSelect} />
        ) : (
          <SelectedAnime
            selectedAnimeKey={key}
            isCreate={isCreate}
            isThreadView={isThreadView}
          />
        )}
      </div>
    </div>
  );
}
