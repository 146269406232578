import { demonSlayer, naruto, onePiece } from "../../assets/anime";

export const animeShowsConfig = [
    {
        cover: onePiece,
        titleKey: "community_one_piece",
        key: "one_piece",
    },
    {
        cover: demonSlayer,
        titleKey: "community_demon_slayer",
        key: "demon_slayer",
    },
    {
        cover: naruto,
        titleKey: "community_demon_naruto",
        key: "naruto",
    },
];