import React, { useEffect, useState } from "react";
import { logo } from "../../assets/brand";
import ShareSection from "../../components/ShareSection";
import {
  GirlAnalyst,
  GirlInBalloon,
  GirlWithNotepad,
  GirlWithParachute,
} from "../../assets/illustrations";
import {
  ColouredProgressIndicator,
  HorizontalLine,
  RedSectionTitle,
  SectionText,
  WhiteCard,
} from "../../components/PageCommons";
import { GoldenBannerFixed, LogoutIcon, nativevsmbti } from "../../assets";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { logoutApp } from "../../utils/auth";
import useResetScroll from "../../utils/useResetScroll";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import { useTranslation } from "react-i18next";
import { createNativeCardTourConfig } from "../../utils/joyrideTour";
import JoyRideTour from "../../components/JoyRideTour";

const IS_NATIVE_CARD_TOUR_COMPLETED = "IS_NATIVE_CARD_TOUR_COMPLETED";

export default function NativeCard({ isCustom = false }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  useResetScroll();
  const [searchParams] = useSearchParams();

  const {
    day: urlDay,
    month: urlMonth,
    year: urlYear,
    name: urlName,
  } = useParams();

  const referrerIdFromUrl = searchParams.get("referrer-id");
  const hideMatchingButton = searchParams.get("hideMatchingButton") === "true";
  const skipMatchingPageNavigation =
    searchParams.get("skipMatchingPageNavigation") === "true";
  if (!!referrerIdFromUrl) {
    localStorage.setItem("REFERRER_ID", referrerIdFromUrl);
  }

  const userId = localStorage.getItem("USER_ID");
  const referrerId = localStorage.getItem("REFERRER_ID");
  const refreshToken = localStorage.getItem("REFRESH_TOKEN");
  const isReferred = !!localStorage.getItem("REFERRER_ID");

  const isUserAuthenticated =
    userId && userId?.length > 0 && userId !== "undefined";
  const isMobile = window.innerWidth < 450;

  const navigate = useNavigate();

  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [mtbiData, setMTBIData] = useState();

  const [referrerName, setReferrerName] = useState();
  const [name, setName] = useState("");
  const [dob, setDob] = useState({});

  const [showTour, setShowTour] = useState(
    localStorage.getItem(IS_NATIVE_CARD_TOUR_COMPLETED) !== "true" &&
      !referrerIdFromUrl
  );

  const fetchData = async () => {
    setIsLoading(true);

    const userInfoAPIRes = await fetch(
      ` https://api.be-native.life/api/users/${referrerIdFromUrl ?? userId}`
    );
    const userInfo = await userInfoAPIRes.json();

    if (!!referrerId) {
      const referrerInfoAPIRes = await fetch(
        `https://api.be-native.life/api/users/${referrerId}`
      );
      const referrerInfo = await referrerInfoAPIRes.json();
      setReferrerName(referrerInfo?.input_name);
    }

    const date = userInfo?.dob?.split("-")?.[2];
    const month = userInfo?.dob?.split("-")?.[1];
    const year = userInfo?.dob?.split("-")?.[0];

    setDob({
      date,
      month,
      year,
    });
    setName(isCustom ? urlName : userInfo?.input_name);

    const finalTokenResponse = await fetch(
      ` https://api.be-native.life/api/cognito/refresh_access_token?refresh_token=${refreshToken}`,
      {
        method: "POST",
      }
    );
    const finalTokenData = await finalTokenResponse.json();
    const tokenForHeader = finalTokenData?.access_token;

    const apiResponse = await fetch(
      ` https://api.be-native.life/api/friends/friends_data/${
        isCustom ? urlYear : year
      }/${isCustom ? urlMonth : month}/${
        isCustom ? urlDay : date
      }?lang=${language}`,
      {
        headers: {
          ...(!!tokenForHeader
            ? {
                Authorization: "Bearer " + tokenForHeader,
              }
            : {}),
        },
      }
    );
    const apiData = await apiResponse.json();
    const mtbiAPIResponse = await fetch(
      ` https://api.be-native.life/api/friends/get_MBTI_score/${
        isCustom ? urlYear : year
      }/${isCustom ? urlMonth : month}/${isCustom ? urlDay : date}`
    );
    const mtbiAPIData = await mtbiAPIResponse.json();
    setData(apiData);
    setMTBIData(mtbiAPIData);

    if (!referrerIdFromUrl && !isCustom) {
      localStorage.setItem("USER_NAME", userInfo?.input_name);
      localStorage.setItem("USER_QR_COLOR", apiData?.hex_color_qr);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [language]);

  // Top Card
  const mainColor = data?.hex_color;
  const qrCodeColor = data?.hex_color_qr;
  const userName = name;
  const userType = data?.param7;
  const personalChara = data?.param8;
  const catchTitle = data?.param9;

  // Card One
  const nativeScore = Math.round(Number(data?.native_score) * 2.77);

  // Card Two
  const personalHash1 = `#${data?.param10?.split("#")?.[1]}`;
  const personalHash2 = `#${data?.param10?.split("#")?.[2]}`;
  const personalHash3 = `#${data?.param10?.split("#")?.[3]}`;
  const personality1 = data?.param11;
  const kanji1 = `#${data?.param13?.split("#")?.[1]}`;
  const kanji2 = `#${data?.param13?.split("#")?.[2]}`;
  const kanji3 = `#${data?.param13?.split("#")?.[3]}`;

  // Card Three
  const motivationMoment1 = data?.param14;
  const demotivationMoment1 = data?.param16;

  // Card Four
  const fitChara1 = data?.partner_1;
  const fitChara1Hash1 = `#${data?.best_partner_hashtag1?.split("#")?.[1]}`;
  const fitChara1Hash2 = `#${data?.best_partner_hashtag1?.split("#")?.[2]}`;
  const fitChara1Title = data?.best_partner_text_1;

  const fitChara2 = data?.partner_2;
  const fitChara2Hash1 = `#${data?.best_partner_hashtag2?.split("#")?.[1]}`;
  const fitChara2Hash2 = `#${data?.best_partner_hashtag2?.split("#")?.[2]}`;
  const fitChara2Title = data?.best_partner_text_2;

  const getAlongScore1 = Math.round(nativeScore - 13.85);
  const getAlongScore2 = Math.round(nativeScore + 13.85);

  //Card Five
  const card5Title = mtbiData?.mbti_type;
  const card5SubTitle = mtbiData?.mbti_tag;
  const percentage1 = Object.values(mtbiData?.param_1 ?? {})?.[0];
  const percentage2 = Object.values(mtbiData?.param_2 ?? {})?.[0];
  const percentage3 = Object.values(mtbiData?.param_3 ?? {})?.[0];
  const percentage4 = Object.values(mtbiData?.param_4 ?? {})?.[0];
  const percentage5 = Object.values(mtbiData?.param_5 ?? {})?.[0];

  if (!referrerIdFromUrl && !isCustom && !!userType) {
    localStorage.setItem("USER_NATIVE_TYPE", userType);
  }

  const getPercentageLabel = (key) => {
    switch (key) {
      case "E":
        return t("native_card_personality_type_Extroverted");
      case "I":
        return t("native_card_personality_type_Introverted");
      case "S":
        return t("native_card_personality_type_Intuitive");
      case "N":
        return t("native_card_personality_type_Observant");
      case "T":
        return t("native_card_personality_type_Thinking");
      case "F":
        return t("native_card_personality_type_Feeling");
      case "J":
        return t("native_card_personality_type_Judging");
      case "P":
        return t("native_card_personality_type_Prospecting");
      case "A":
        return t("native_card_personality_type_Assertive");
      case "B":
        return t("native_card_personality_type_Turbulent");
    }
  };

  //Card Seven
  const personality2 = data?.param12;

  //Card Eight
  const motivationMoment2 = data?.param15;
  const demotivationMoment2 = data?.param17;

  //Card Nine
  const toreitsu1 = data?.param19;
  const toreitsu1Desc = data?.param20;
  const toreitsu2 = data?.param21;
  const toreitsu2Desc = data?.param22;
  const toreitsu3 = data?.param23;
  const toreitsu3Desc = data?.param24;

  const shareLink = isCustom
    ? `${window.location.href}?openExternalBrowser=1`
    : `${window.location.href}?referrer-id=${userId}&openExternalBrowser=1`;

  const handleIssueInstantly = () => {
    navigate("/login");
  };

  const handleCompatible = () => {
    navigate(`/compatibility/${userId}/${referrerId}`);
  };

  const handleMatchPage = () => {
    navigate("/matched");
  };

  useEffect(() => {
    if (userId && referrerIdFromUrl && !skipMatchingPageNavigation) {
      handleCompatible();
    }
  }, []);

  const handleTourEnd = () => {
    setShowTour(false);
    localStorage.setItem(IS_NATIVE_CARD_TOUR_COMPLETED, "true");
  };

  return isLoading ? (
    <div className="min-h-[100svh] w-full flex flex-col items-center justify-center gap-2">
      <p className="text-[24px] font-bold text-neutral-800">
        <RenderTranslatedText i8nKey={"loading_text"} />
      </p>
    </div>
  ) : (
    <div className="otaPageBg">
      <div className="mx-auto max-w-[450px] w-full flex flex-col items-center gap-5 min-h-[100svh] pb-10">
        {showTour ? (
          <JoyRideTour
            config={createNativeCardTourConfig(language)}
            onTourEnd={handleTourEnd}
            onCloseClick={handleTourEnd}
          />
        ) : null}
        {isReferred && !userId ? (
          <button
            className="fixed  font-bold left-[50%] text-[18px] bg-[#FF8D8D] text-white w-[200px] h-[50px] flex items-center justify-center rounded-[16px] z-50"
            style={{
              transform: "translate(-50%,-50%)",
              zIndex: 99,
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              bottom: isUserAuthenticated && isMobile ? "100px" : "36px",
            }}
            onClick={handleIssueInstantly}
          >
            {t("native_card_page_issue_now_CTA")}⁨⁩⁨⁩ ▶︎
          </button>
        ) : null}
        {/* Top Card */}
        <div
          className="w-[95%] mx-auto flex flex-col gap-4 bg-white rounded-br-[20px] rounded-bl-[20px] min-h-[460px] overflow-hidden relative"
          style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
        >
          {/* Design Elements Start */}
          <div className="absolute right-5 top-10 grid grid-cols-4 gap-2">
            {Array(24)
              .fill("")
              .map(() => {
                return (
                  <div
                    className="z-0 h-[6px] w-[6px] rounded-full opacity-70"
                    style={{ background: mainColor }}
                  />
                );
              })}
          </div>

          <div className="absolute left-5 bottom-28 grid grid-cols-6 gap-2">
            {Array(24)
              .fill("")
              .map(() => {
                return (
                  <div
                    className="z-0 h-[6px] w-[6px] rounded-full opacity-70"
                    style={{ background: mainColor }}
                  />
                );
              })}
          </div>

          <div
            className="w-[75%] flex items-center justify-between absolute top-[110px] left-[50%]"
            style={{ transform: "translate(-45%)" }}
          >
            <div
              className="w-7 h-7 rounded-full opacity-80"
              style={{ background: mainColor }}
            />
            <div
              className="w-16 h-16 rounded-full opacity-30"
              style={{ background: mainColor }}
            />
          </div>

          <div
            className="w-5 h-5 rounded-full absolute top-[180px] left-8"
            style={{ background: mainColor }}
          />

          <div
            className="w-[75%] flex items-center justify-between absolute top-[230px] left-[50%]"
            style={{ transform: "translate(-50%)" }}
          >
            <div
              className="w-12 h-12 rounded-full opacity-50"
              style={{ background: mainColor }}
            />
            <div
              className="w-7 h-7 rounded-full opacity-80"
              style={{ background: mainColor }}
            />
          </div>
          {/* Design Elements End */}

          <div
            className="w-full flex flex-col items-center gap-5 z-10"
            style={{ borderTop: `8px solid ${mainColor}` }}
          >
            <h1
              className="text-[18px] text-black font-bold text-center mt-10 w-[70%] mx-auto"
              dangerouslySetInnerHTML={{
                __html: t("native_card_title", { userName }),
              }}
            />
            <div className="flex flex-col gap-1 mt-1 w-[50%] mx-auto">
              <p
                style={{ color: mainColor }}
                className="text-[14px] text-center font-semibold"
              >
                {personalChara}
              </p>
              <p
                style={{ color: mainColor }}
                className="text-[42px] text-center font-bold"
              >
                {userType}
              </p>
            </div>
            {userType && (
              <img
                alt=""
                src={`https://api.be-native.life/api/friends/charimg/${userType}`}
                style={{
                  zIndex: 1,
                  width: "200px",
                  height: "200px",
                  marginTop: "-24px",
                }}
              />
            )}
            <div className="flex flex-col gap-1 mt-2">
              <p className="text-[14px] text-black font-semibold text-center w-[90%] m-auto mb-5">
                {catchTitle}
              </p>
            </div>
            <div
              style={{ background: mainColor, opacity: 0.3 }}
              className="w-[660px] h-[708px] rounded-[860px] absolute bottom-[-546px] z-0"
            />
          </div>
        </div>

        {/* Card One */}
        <div className="native-score-card w-full">
          <WhiteCard>
            <RedSectionTitle>
              <RenderTranslatedText i8nKey={"native_card_score_title"} />
            </RedSectionTitle>
            <HorizontalLine />
            <div className="flex flex-row gap-4 items-start">
              <p className="text-[42px] font-bold" style={{ color: mainColor }}>
                {nativeScore}
              </p>
              <div className="w-full flex flex-col gap-2 mt-3">
                <div className="w-full grid grid-cols-3 gap-1">
                  <div className="w-full flex flex-col gap-1 items-center">
                    <p
                      className="font-semibold text-[#aaa]"
                      style={{ fontSize: language === "en" ? "10px" : "12px" }}
                    >
                      <RenderTranslatedText
                        i8nKey={"native_card_progress_bar_text_1"}
                      />
                    </p>
                  </div>
                  <div className="w-full flex flex-col gap-1 items-center">
                    <p
                      className="font-semibold text-[#aaa]"
                      style={{ fontSize: language === "en" ? "10px" : "12px" }}
                    >
                      <RenderTranslatedText
                        i8nKey={"native_card_progress_bar_text_2"}
                      />
                    </p>
                  </div>
                  <div className="w-full flex flex-col gap-1 items-center">
                    <p
                      className="font-semibold text-[#aaa]"
                      style={{ fontSize: language === "en" ? "10px" : "12px" }}
                    >
                      <RenderTranslatedText
                        i8nKey={"native_card_progress_bar_text_3"}
                      />
                    </p>
                  </div>
                </div>
                <div className="relative w-full flex flex-row items-center mb-2">
                  <div className="absolute" style={{ left: `0%` }}>
                    <p className="font-semibold text-[#aaa] text-[12px]">1</p>
                  </div>
                  <div className="absolute" style={{ left: `33%` }}>
                    <p
                      className="font-semibold text-[#aaa] text-[12px] pr-1"
                      style={{ borderRight: "0.4px solid #aaa" }}
                    >
                      33
                    </p>
                  </div>
                  <div className="absolute" style={{ left: `66%` }}>
                    <p
                      className="font-semibold text-[#aaa] text-[12px] pl-1"
                      style={{ borderLeft: "0.4px solid #aaa" }}
                    >
                      66
                    </p>
                  </div>
                  <div className="absolute" style={{ left: `92%` }}>
                    <p className="font-semibold text-[#aaa] text-[12px]">100</p>
                  </div>
                </div>
                <div
                  className="h-[12px] rounded-full overflow-hidden flex flex-row items-center relative"
                  style={{
                    background:
                      "linear-gradient(90deg, rgba(142,135,227,1) 0%, rgba(187,135,227,1) 35%, rgba(227,135,213,1) 100%)",
                  }}
                >
                  <div
                    className="w-[15px] h-[15px] bg-[#AAA] rounded-full absolute "
                    style={{
                      border: "3px solid #fff",
                      left: `${getAlongScore1}%`,
                    }}
                  />
                  <div
                    className="w-[15px] h-[15px] bg-[#FF8D8D] rounded-full absolute "
                    style={{
                      border: "3px solid #fff",
                      left: `${nativeScore}%`,
                    }}
                  />
                  <div
                    className="w-[15px] h-[15px] bg-[#AAA] rounded-full absolute "
                    style={{
                      border: "3px solid #fff",
                      left: `${getAlongScore2}%`,
                    }}
                  />
                </div>
                <div className="w-full relative">
                  <div
                    className="h-3 w-[1px] bg-[#AAA] absolute ml-[7px]"
                    style={{ left: `${getAlongScore1}%` }}
                  />
                  <div
                    className="h-3 w-[1px] bg-[#AAA] absolute ml-[7px]"
                    style={{ left: `${getAlongScore2}%` }}
                  />
                  <div
                    className="h-[1px] bg-[#AAA] top-[11px] absolute ml-[7.5px]"
                    style={{
                      left: `${getAlongScore1}%`,
                      width: `${getAlongScore2 - getAlongScore1}%`,
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className="mt-[20px] mx-auto"
              style={{
                left: `${getAlongScore1 - 25}%`,
              }}
            >
              <p className="font-semibold text-black text-[12px] whitespace-nowrap">
                {t("native_card_progress_bar_compatibility_range_text", {
                  compatibilityRange: `${getAlongScore1}~${getAlongScore2}`,
                })}
              </p>
            </div>
          </WhiteCard>
        </div>

        <div className="">
          <WhiteCard>
            <div className="four-steps">
              <p className="mt-5 text-center text-[18px] font-semibold text-[#FF8D8D] mb-[-12px]">
                <RenderTranslatedText
                  i8nKey={"native_card_golden_banner_heading_text"}
                />
              </p>
              <p className="mt-5 text-center text-[12px] font-semibold">
                <RenderTranslatedText
                  i8nKey={"native_card_golden_banner_heading_subtext"}
                />
              </p>
              <img
                className="w-[95%] mx-auto object-contain"
                src={GoldenBannerFixed}
              />
            </div>
            <ShareSection
              noShadow
              name={name}
              shareLink={shareLink}
              mainColor={qrCodeColor}
              showQR
              customShareTitle={
                "次のMBTI！生年月日入力のみ。1秒で自分の可能性を拓く｜1秒友達相性診断サービス「native. for Friends」"
              }
            />
          </WhiteCard>
        </div>

        {/* Card Four */}
        <WhiteCard>
          <div className="compatible-character">
            <RedSectionTitle>
              <RenderTranslatedText
                i8nKey={"native_card_compatibility_character_heading"}
              />
            </RedSectionTitle>
          </div>
          <HorizontalLine />
          <div
            className="w-full grid gap-5 mt-4 overflow-hidden"
            style={{ gridTemplateColumns: "1fr 0.4px 1fr" }}
          >
            <div className="w-full flex flex-col items-center gap-2">
              {fitChara1 && (
                <img
                  alt=""
                  src={`https://api.be-native.life/api/friends/charimg/${fitChara1}`}
                  className="w-[120px] h-[120px]"
                  style={{ zIndex: 1 }}
                />
              )}
              <p
                className="text-[28px] font-semibold text-center"
                style={{ color: data?.hex1 }}
              >
                {fitChara1}
              </p>
              <div className="flex flex-col items-center justify-center gap-1">
                <p
                  className="text-[12px] text-center font-semibold"
                  style={{ color: data?.hex1 }}
                >
                  {fitChara1Hash1}
                </p>
                <p
                  className="text-[12px] text-center font-semibold"
                  style={{ color: data?.hex1 }}
                >
                  {fitChara1Hash2}
                </p>
              </div>
              <SectionText align="left">{fitChara1Title}</SectionText>
            </div>
            <div className="w-[0.4px] h-[100%] bg-black" />
            <div className="w-full flex flex-col items-center gap-2">
              {fitChara2 && (
                <img
                  alt=""
                  src={`https://api.be-native.life/api/friends/charimg/${fitChara2}`}
                  className="w-[120px] h-[120px]"
                  style={{ zIndex: 1 }}
                />
              )}
              <p
                className="text-[28px] font-semibold text-center"
                style={{ color: data?.hex2 }}
              >
                {fitChara2}
              </p>
              <div className="flex flex-col items-center justify-center gap-1">
                <p
                  className="text-[12px] text-center font-semibold"
                  style={{ color: data?.hex2 }}
                >
                  {fitChara2Hash1}
                </p>
                <p
                  className="text-[12px] text-center font-semibold"
                  style={{ color: data?.hex2 }}
                >
                  {fitChara2Hash2}
                </p>
              </div>
              <SectionText align="left">{fitChara2Title}</SectionText>
            </div>
          </div>
          <div className="h-10" />
          <RedSectionTitle>
            <RenderTranslatedText
              i8nKey={"native_card_celebrities_of_each_other_heading"}
            />
          </RedSectionTitle>
          <div
            className="w-full grid gap-5 mt-4 mb-5 overflow-hidden"
            style={{ gridTemplateColumns: "1fr 0.4px 1fr" }}
          >
            <div className="w-full flex flex-col items-center gap-2">
              <div className="flex flex-col items-center justify-center gap-1">
                {data?.celeb1?.split("\n").map((celeb) => {
                  return (
                    <p
                      className="text-[12px] text-center font-semibold"
                      style={{ color: data?.hex1 }}
                    >
                      {celeb}
                    </p>
                  );
                })}
              </div>
            </div>
            <div className="w-[0.4px] h-[100%] bg-black" />
            <div className="w-full flex flex-col items-center gap-2">
              <div className="flex flex-col items-center justify-center gap-1">
                {data?.celeb2?.split("\n").map((celeb) => {
                  return (
                    <p
                      className="text-[12px] text-center font-semibold"
                      style={{ color: data?.hex2 }}
                    >
                      {celeb}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
          {/* <div className="h-5" />
        <HorizontalLine />
        <div className="mx-3 my-0">
          <SectionText align="left" thin>
            {secretGetAlong}
          </SectionText>
        </div> */}
        </WhiteCard>

        {/* Card Five */}
        <WhiteCard>
          {/* <div className="mbti-results">
          <p className="text-[#BB9DC6] font-semibold text-[18px] text-center">
            <RenderTranslatedText i8nKey={"native_card_mbti_info_heading"} />
          </p>
          <div className="flex items-center gap-5 justify-around my-5">
            <img src={BlueChar} className="w-[100px]" />
            <p className="text-[10px] text-black">
              {t("native_card_mbti_info_description")}
            </p>
          </div>
        </div> */}

          <RedSectionTitle>
            <RenderTranslatedText i8nKey={"native_card_mbti_red_title"} />
          </RedSectionTitle>

          <div className="flex flex-row justify-around my-5">
            <div className="flex flex-col items-center gap-0 text-[#AC7EC9]">
              <p className="text-[16px] font-bold text-center">
                <RenderTranslatedText
                  i8nKey={"native_card_mbti_native_side_text"}
                />
              </p>
              <p className="text-[24px] font-bold">native.</p>
            </div>
            <div className="flex flex-col items-center gap-0 text-[#66C982]">
              <p className="text-[16px] font-bold text-center">
                <RenderTranslatedText
                  i8nKey={"native_card_mbti_mbti_side_text"}
                />
              </p>
              <p className="text-[24px] font-bold">MBTI</p>
            </div>
          </div>

          <img src={nativevsmbti} className="w-full mb-5" />

          <SectionText>{t("native_card_personality_type_heading")}</SectionText>
          <p
            className="font-extrabold text-[24px] text-[#56A278] text-center"
            style={{ fontWeight: 900 }}
          >
            <b>{card5Title}</b>
          </p>
          <SectionText>{card5SubTitle}</SectionText>
          <div className="h-3" />
          <p className="text-[#FF8D8D] font-semibold text-[16px] text-center">
            <RenderTranslatedText i8nKey={"native_card_personality_traits"} />
          </p>
          <div className="h-5" />
          <ColouredProgressIndicator
            color="#6894AE"
            percentage={percentage1}
            type={getPercentageLabel(Object.keys(mtbiData?.param_1 ?? {})?.[0])}
            startIndicator={t("native_card_personality_type_Extroverted")}
            endIndicator={t("native_card_personality_type_Introverted")}
          />
          <ColouredProgressIndicator
            color="#D5B260"
            percentage={percentage2}
            type={getPercentageLabel(Object.keys(mtbiData?.param_2 ?? {})?.[0])}
            startIndicator={t("native_card_personality_type_Intuitive")}
            endIndicator={t("native_card_personality_type_Observant")}
          />
          <ColouredProgressIndicator
            color="#69A07B"
            percentage={percentage3}
            type={getPercentageLabel(Object.keys(mtbiData?.param_3 ?? {})?.[0])}
            startIndicator={t("native_card_personality_type_Thinking")}
            endIndicator={t("native_card_personality_type_Feeling")}
          />
          <ColouredProgressIndicator
            color="#7D6494"
            percentage={percentage4}
            type={getPercentageLabel(Object.keys(mtbiData?.param_4 ?? {})?.[0])}
            startIndicator={t("native_card_personality_type_Judging")}
            endIndicator={t("native_card_personality_type_Prospecting")}
          />
          <ColouredProgressIndicator
            color="#D16E6B"
            percentage={percentage5}
            type={getPercentageLabel(Object.keys(mtbiData?.param_5 ?? {})?.[0])}
            startIndicator={t("native_card_personality_type_Assertive")}
            endIndicator={t("native_card_personality_type_Turbulent")}
          />
        </WhiteCard>

        {/* Card Two */}
        <WhiteCard>
          <RedSectionTitle>
            <RenderTranslatedText
              i8nKey={"native_card_personality_card_title"}
            />
          </RedSectionTitle>
          <HorizontalLine />
          <div className="mx-auto">
            <img alt="" src={GirlWithNotepad} className="w-[150px] h-[120px]" />
          </div>
          <div className="flex flex-row items-center justify-center gap-4 flex-wrap">
            <SectionText>{personalHash1}</SectionText>
            <SectionText>{personalHash2}</SectionText>
            <SectionText>{personalHash3}</SectionText>
          </div>
          <SectionText>{personality1}</SectionText>
          <div className="h-5" />
          <RedSectionTitle>
            {t("native_card_personality_card_celebrity_text")}
          </RedSectionTitle>
          <HorizontalLine />
          <div className="flex flex-row items-center justify-center gap-4">
            <SectionText>{kanji1}</SectionText>
            <SectionText>{kanji2}</SectionText>
            <SectionText>{kanji3}</SectionText>
          </div>
        </WhiteCard>

        {/* Card Seven */}
        <WhiteCard>
          <RedSectionTitle>
            {t("native_card_pros_cons_card_heading")}
          </RedSectionTitle>
          <HorizontalLine />
          <div className="mx-auto">
            <img alt="" src={GirlAnalyst} className="w-[150px] h-[120px]" />
          </div>
          <SectionText>{personality2}</SectionText>
          <div className="h-5" />
        </WhiteCard>

        {/* Card Three */}
        <WhiteCard>
          <RedSectionTitle>
            <RenderTranslatedText
              i8nKey={"native_card_moment_of_tension_up_heading"}
            />
          </RedSectionTitle>
          <HorizontalLine />
          <div className="mx-auto">
            <img alt="" src={GirlInBalloon} className="w-[150px] h-[120px]" />
          </div>
          <SectionText>{motivationMoment1}</SectionText>
          <SectionText>{motivationMoment2}</SectionText>
          <div className="h-5" />
          <RedSectionTitle>
            <RenderTranslatedText
              i8nKey={"native_card_moment_of_tension_down_heading"}
            />
          </RedSectionTitle>
          <HorizontalLine />
          <div className="mx-auto">
            <img
              alt=""
              src={GirlWithParachute}
              className="w-[150px] h-[120px]"
            />
          </div>
          <SectionText>{demotivationMoment1}</SectionText>
          <SectionText>{demotivationMoment2}</SectionText>
        </WhiteCard>

        <ShareSection
          name={name}
          shareLink={shareLink}
          mainColor={qrCodeColor}
          showQR
          customShareTitle={
            "次のMBTI！生年月日入力のみ。1秒で自分の可能性を拓く｜1秒友達相性診断サービス「native. for Friends」"
          }
        />

        <div className="h-5" />

        {userId && referrerId && !hideMatchingButton && referrerName ? (
          <button
            className="fixed left-[50%] font-bold text-[14px] bg-[#FF8D8D] text-white w-max h-[50px] flex items-center justify-center rounded-[16px] px-4 z-50"
            style={{
              transform: "translate(-50%,-50%)",
              zIndex: 99,
              bottom: isUserAuthenticated && isMobile ? "100px" : "36px",
            }}
            onClick={handleCompatible}
          >
            {t("native_check_compatibility_with_CTA", {
              name: decodeURIComponent(`${referrerName}`),
            })}
          </button>
        ) : null}

        {userId ? (
          <button
            onClick={handleMatchPage}
            className=" border-none w-[85%] mx-auto h-[70px] flex items-center justify-center bg-[#FF8D8D] opacity-85 rounded-[20px]"
            style={{
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            }}
          >
            <p className="text-white text-[16px] font-semibold">
              <RenderTranslatedText
                i8nKey={"native_card_compatibility_result_list_CTA"}
              />
            </p>
          </button>
        ) : null}

        {userId ? (
          <button
            onClick={logoutApp}
            className=" border-none w-[85%] mx-auto h-[70px] flex items-center justify-center bg-[#AAAAAA] opacity-85 rounded-[20px] gap-3"
            style={{
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            }}
          >
            <p className="text-white text-[16px] font-semibold">
              <RenderTranslatedText i8nKey={"native_card_logout_CTA"} />
            </p>
            <img src={LogoutIcon} alt="" className="h-[20px] w-[20px]" />
          </button>
        ) : null}

        {/* Footer */}
        <div className="flex flex-col items-center mt-10 mb-1">
          <img alt="" src={logo} style={{ zIndex: 1, width: "150px" }} />
        </div>
        <p className="text-center text-[8px] text-[#A1A1AA]">
          © 2024 native.K.K All Rights Reserved.
        </p>
      </div>
    </div>
  );
}
