import { useEffect, useState } from "react";
import { logo } from "../../assets/brand";
import {
  darkGreenChar,
  greenChar,
  orangeChar,
  pinkChar,
  yellowChar,
  brownChar,
  darkBlueChar,
  greyChar,
  lightBlueChar,
  purpleChar,
} from "../../assets";
import { CustomInput } from "../../components/CustomInput";
import DatePicker from "react-mobile-datepicker";
import { useNavigate } from "react-router-dom";
import useResetScroll from "../../utils/useResetScroll";
import ShareSection from "../../components/ShareSection";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import { useTranslation } from "react-i18next";
import { getDatePickerConfig } from "../../utils/misc";

const topCharacters = [
  greenChar,
  pinkChar,
  orangeChar,
  yellowChar,
  darkGreenChar,
];

const bottomCharacters = [
  brownChar,
  greyChar,
  purpleChar,
  darkBlueChar,
  lightBlueChar,
];

const MATCH_WITH_SINGLE_USER = "MATCH_WITH_SINGLE_USER";
const MATCH_WITH_DOUBLE_USER = "MATCH_WITH_DOUBLE_USER";

export default function SelfMatching() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  useResetScroll();
  const navigate = useNavigate();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [selectedType, setSelectedType] = useState(MATCH_WITH_SINGLE_USER);

  const [name, setName] = useState();
  const [date, setDate] = useState();
  const [dateInputVal, setDateInputVal] = useState(new Date("2000-07-15"));
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const [name2, setName2] = useState();
  const [date2, setDate2] = useState();
  const [dateInputVal2, setDateInputVal2] = useState(new Date("2000-07-15"));
  const [isDatePickerOpen2, setIsDatePickerOpen2] = useState(false);

  useEffect(() => {
    let timer = setTimeout(() => {
      setIsPageLoading(false);
    }, 500);
    return () => {
      setIsPageLoading(true);
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    setName("");
    setDate("");
    setDateInputVal(new Date("2000-07-15"));
    setIsDatePickerOpen(false);
    setName2("");
    setDate2("");
    setDateInputVal2(new Date("2000-07-15"));
    setIsDatePickerOpen2(false);
  }, [selectedType]);

  const handleCheckCompatibility = () => {
    const userId = localStorage.getItem("USER_ID");
    if (!userId || userId?.length === 0) navigate("/");

    const year = date?.split("-")?.[0];
    const month = date?.split("-")?.[1];
    const day = date?.split("-")?.[2];
    const year2 = date2?.split("-")?.[0];
    const month2 = date2?.split("-")?.[1];
    const day2 = date2?.split("-")?.[2];

    if (selectedType === MATCH_WITH_SINGLE_USER) {
      if (!name || !date) return;
      navigate(`/compatibility/${userId}/${name}/${year}/${month}/${day}`);
    } else {
      if (!name || !date || !name2 || !date2) return;
      navigate(
        `/compatibility/${name}/${year}/${month}/${day}/${name2}/${year2}/${month2}/${day2}`
      );
    }
  };

  const userName = localStorage.getItem("USER_NAME");
  const userId = localStorage.getItem("USER_ID");
  const shareLink = `${window.location.origin}/native-card?referrer-id=${userId}&openExternalBrowser=1 `;

  return isPageLoading ? (
    <div className="h-[100svh] w-full flex-col flex items-center justify-center">
      <img alt="" src={logo} style={{ zIndex: 1, width: "180px" }} />
    </div>
  ) : (
    <div className="max-w-[450px] m-auto flex flex-col gap-5 items-center min-h-[100svh] bg-white">
      <p
        className="text-center font-bold text-[24px] mt-10"
        style={{ letterSpacing: "2.88px" }}
      >
        native. for friends
      </p>
      <div className="flex flex-row items-center justify-center gap-3 mt-3">
        {topCharacters.map((image, index) => {
          return (
            <img src={image} alt="" key={index} className="w-[40px] h-[40px]" />
          );
        })}
      </div>
      <ShareSection name={userName} shareLink={shareLink} showQR noShadow />
      <p className="text-[16px] font-semibold text-center">
        <RenderTranslatedText i8nKey={"self_matching_page_title"} />
      </p>
      <p className="text-center text-[14px] w-[80%] mx-auto">
        <RenderTranslatedText i8nKey={"self_matching_page_subtitle"} />
      </p>
      <div className="w-[340px] flex items-center gap-5 mx-auto my-5">
        <div
          onClick={() => setSelectedType(MATCH_WITH_SINGLE_USER)}
          className="px-5 py-2 flex items-center justify-center gap-3 rounded-md w-[170px] cursor-pointer"
          style={{
            boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.16)",
            transition: "0.2s ease",
            border:
              selectedType === MATCH_WITH_SINGLE_USER
                ? "2px solid #EC736E"
                : "2px solid #fff",
          }}
        >
          <p className="text-[14px] font-semibold whitespace-nowrap">
            <RenderTranslatedText i8nKey={"self_matching_page_yourself_TAB"} />
          </p>
          <div
            className="w-2 h-2 rounded-full "
            style={{
              transition: "0.2s ease",
              backgroundColor:
                selectedType === MATCH_WITH_SINGLE_USER ? "#FF8D8D" : "#fff",
            }}
          />
        </div>
        <div
          onClick={() => setSelectedType(MATCH_WITH_DOUBLE_USER)}
          className="px-5 py-2 flex items-center justify-center gap-3 rounded-md w-[170px] cursor-pointer"
          style={{
            boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.16)",
            transition: "0.2s ease",
            border:
              selectedType === MATCH_WITH_DOUBLE_USER
                ? "2px solid #EC736E"
                : "2px solid #fff",
          }}
        >
          <p className="text-[14px] font-semibold whitespace-nowrap">
            <RenderTranslatedText
              i8nKey={"self_matching_page_between_friends_TAB"}
            />
          </p>
          <div
            className="w-2 h-2 min-w-2 min-h-2 rounded-full "
            style={{
              transition: "0.2s ease",
              backgroundColor:
                selectedType === MATCH_WITH_DOUBLE_USER ? "#FF8D8D" : "#fff",
            }}
          />
        </div>
      </div>
      {selectedType === MATCH_WITH_SINGLE_USER ? (
        <div className="w-[80%] mx-auto flex flex-col mb-5 gap-5">
          <CustomInput
            label={t("self_matching_page_nickname_label_1")}
            onChange={(value) => setName(value)}
            value={name}
            placeholder={t("self_matching_page_placeholder")}
            type="text"
          />
          <div
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setIsDatePickerOpen(true);
            }}
          >
            <CustomInput
              label={t("self_matching_page_dob_label_1")}
              onChange={() => {}}
              value={date ?? ""}
              placeholder={language === "en" ? "DD/MM/YYYY" : "YYYY/MM/DD"}
              type="date"
            />
          </div>
          {isDatePickerOpen ? (
            <div className="relative top-[100px] z-50">
              <DatePicker
                value={dateInputVal}
                isOpen={isDatePickerOpen}
                onSelect={(val) => {
                  setDateInputVal(val);
                  setDate(new Date(val).toISOString()?.split("T")?.[0]);
                  setIsDatePickerOpen(false);
                }}
                onCancel={() => {
                  setIsDatePickerOpen(false);
                }}
                confirmText={t("dob_picker_ok")}
                cancelText={t("dob_picker_cancel")}
                min={new Date("01-01-1950")}
                isPopup={false}
                dateConfig={getDatePickerConfig(language)}
                showHeader={false}
              />
            </div>
          ) : null}
        </div>
      ) : (
        <div className="w-[80%] mx-auto flex flex-col gap-5">
          <CustomInput
            label={t("self_matching_page_nickname_label_1")}
            onChange={(value) => setName(value)}
            value={name}
            placeholder={t("self_matching_page_placeholder")}
            type="text"
          />
          <div
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setIsDatePickerOpen(true);
            }}
          >
            <CustomInput
              label={t("self_matching_page_dob_label_1")}
              onChange={() => {}}
              value={date ?? ""}
              placeholder={language === "en" ? "DD/MM/YYYY" : "YYYY/MM/DD"}
              type="date"
            />
          </div>
          {isDatePickerOpen ? (
            <div className="relative top-[100px] z-50">
              <DatePicker
                value={dateInputVal}
                isOpen={isDatePickerOpen}
                onSelect={(val) => {
                  setDateInputVal(val);
                  setDate(new Date(val).toISOString()?.split("T")?.[0]);
                  setIsDatePickerOpen(false);
                }}
                onCancel={() => {
                  setIsDatePickerOpen(false);
                }}
                confirmText={t("dob_picker_ok")}
                cancelText={t("dob_picker_cancel")}
                min={new Date("01-01-1950")}
                isPopup={false}
                dateConfig={getDatePickerConfig(language)}
                showHeader={false}
              />
            </div>
          ) : null}
          <div className="h-5" />
          <CustomInput
            label={t("self_matching_page_nickname_label_2")}
            onChange={(value) => setName2(value)}
            value={name2}
            placeholder={t("self_matching_page_placeholder")}
            type="text"
          />
          <div
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setIsDatePickerOpen2(true);
            }}
          >
            <CustomInput
              label={t("self_matching_page_dob_label_2")}
              onChange={() => {}}
              value={date2 ?? ""}
              placeholder={language === "en" ? "DD/MM/YYYY" : "YYYY/MM/DD"}
              type="date"
            />
          </div>
          {isDatePickerOpen2 ? (
            <div className="relative top-[140px] z-50">
              <DatePicker
                value={dateInputVal2}
                isOpen={isDatePickerOpen2}
                onSelect={(val) => {
                  setDateInputVal2(val);
                  setDate2(new Date(val).toISOString()?.split("T")?.[0]);
                  setIsDatePickerOpen2(false);
                }}
                onCancel={() => {
                  setIsDatePickerOpen2(false);
                }}
                confirmText={t("dob_picker_ok")}
                cancelText={t("dob_picker_cancel")}
                min={new Date("01-01-1950")}
                isPopup={false}
                dateConfig={getDatePickerConfig(language)}
                showHeader={false}
              />
            </div>
          ) : null}
        </div>
      )}
      <button
        className="rounded-[18px] flex justify-center items-center bg-[#EC736E] w-[140px] h-[48px] px-[14px] py-[12px] text-white font-semibold text-[12px]"
        onClick={handleCheckCompatibility}
      >
        <RenderTranslatedText
          i8nKey={"self_matching_page_check_compatibility_CTA"}
        />
      </button>
      <p className="text-black text-[12px] font-semibold mt-5">native. 1.8.3</p>
      <div className="flex flex-row items-center justify-center gap-3">
        {bottomCharacters.map((image, index) => {
          return (
            <img src={image} alt="" key={index} className="w-[40px] h-[40px]" />
          );
        })}
      </div>
    </div>
  );
}
