import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import OtaGradientButton from "../../components/OtaGradientButton";
import { backButton, personIcon } from "../../assets/icons";
import { characterCategoriesConfig } from "../characters/constants";
import { useTranslation } from "react-i18next";
import useResetScroll from "../../utils/useResetScroll";
import { getFileUrl } from "../../components/ImageUpload/constants";

export default function CharactersSingle() {
  useResetScroll();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { charId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  const fetchData = async () => {
    setIsLoading(true);
    const apiResponse = await fetch(
      `https://api.be-native.life/api/character/${charId}`
    );
    const apiData = await apiResponse.json();
    setData(apiData);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [charId]);

  const categoryInfo = characterCategoriesConfig.find(
    ({ value }) => data?.category === value
  );

  const userId = localStorage.getItem("USER_ID");

  const handleCompatibility = () => {
    navigate(
      `/characters/compatibility/${userId}/${encodeURIComponent(
        data?.nickname
      )}/${data?.dob?.split("-")?.[0]}/${data?.dob?.split("-")?.[1]}/${
        data?.dob?.split("-")?.[2]
      }/${charId}`
    );
  };

  return isLoading ? (
    <div className="min-h-[100svh] w-full flex flex-col items-center justify-center gap-2">
      <p className="text-[24px] font-bold text-neutral-800">
        <RenderTranslatedText i8nKey={"loading_text"} />
      </p>
    </div>
  ) : (
    <div className="otaPageBg w-full min-h-[100svh] relative">
      <div className="max-w-[450px] mx-auto relative">
        <div className="w-full min-h-[100svh]">
          <div className="p-5 w-full h-[60px] bg-white flex items-center">
            <div className="max-w-[450px] mx-auto w-full">
              <div className="flex items-center gap-2">
                <div onClick={() => navigate(-1)}>
                  <img className="w-[24px]" src={backButton} />
                </div>
                <p className="font-semibold text-[17px] text-[#414853]">
                  <RenderTranslatedText
                    i8nKey={"characters_create_page_character_detail_title"}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="p-5 max-w-[450px] mx-auto">
            <div className="w-[100%] mx-auto flex flex-col gap-2">
              <img
                src={getFileUrl(data?.picture)}
                className="w-full h-[200px] object-cover object-center rounded-2xl"
              />
              <div className="w-full flex items-center justify-between mt-3">
                <div>
                  <p className="otaDark text-[16px] font-bold">
                    {data?.nickname}
                  </p>
                </div>
                <div className="flex items-center gap-2">
                  <div
                    className="px-2 py-1 rounded-xl flex gap-1 items-center"
                    style={{
                      border: "1px solid #D0D5DD",
                      boxShadow: "0px 1px 2px 0px #1018280D",
                    }}
                  >
                    <img src={personIcon} />
                    <p className="otaLight text-[12px] font-bold">
                      {data?.matching_count}
                    </p>
                  </div>
                  <div
                    className="px-2 py-1 rounded-xl flex gap-1 items-center"
                    style={{
                      border: "1px solid #D0D5DD",
                      boxShadow: "0px 1px 2px 0px #1018280D",
                    }}
                  >
                    <img src={categoryInfo.icon} className="w-[24px]" />
                    <p className="otaLight text-[12px] font-bold">
                      {t(categoryInfo?.labelKey)}
                    </p>
                  </div>
                </div>
              </div>
              <OtaGradientButton
                className="rounded-[10px] bg-[#333] w-[100%] h-[48px] px-[16px] py-[12px] text-white mx-auto mt-10"
                onClick={handleCompatibility}
              >
                <RenderTranslatedText
                  i8nKey={
                    "characters_create_page_character_detail_compatibility_cta"
                  }
                />
              </OtaGradientButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
