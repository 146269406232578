export const authConfig = {
    region: "ap-northeast-1",
    userPoolId: "ap-northeast-1_DdrjkLFC2",
    clientId: "5m6v551ncj819i2g9jhumpicqn",
    redirectUri: window.location.origin,
    domain: "auth.be-native.life",

};

export const logoutApp = () => {
    localStorage.clear();
    window.location.href =
        `https://${authConfig.domain}/logout?client_id=${authConfig.clientId}&logout_uri=${authConfig.redirectUri}`;
}