import { useEffect, useMemo, useState } from "react";
import RenderTranslatedText from "../../../components/RenderTranslatedText";
import { animeShowsConfig } from "../constants";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { commentIcon, heartFilled, heartOutline } from "../../../assets/icons";

export default function SelectedAnime({
  selectedAnimeKey,
  isCreate = false,
  isThreadView = false,
}) {
  const { threadId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [threads, setThreads] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [currentThread, setCurrentThread] = useState();
  const [currentThreadMessages, setCurrentThreadMessages] = useState([]);
  const [postMessage, setPostMessage] = useState("");
  const [triggerAPI, setTriggerAPI] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isPosting, setIsPosting] = useState(false);
  const [isLiking, setIsLiking] = useState(false);

  const selectedAnimeConfig = useMemo(
    () => animeShowsConfig.find(({ key }) => key === selectedAnimeKey),
    [selectedAnimeKey]
  );

  useEffect(() => {
    (async () => {
      window.scrollTo(0, 0);
      setTitle("");
      setDescription("");
      setIsLoading(true);
      const apiRes = await fetch(
        "https://api.be-native.life/api/travel/favthreads"
      );
      const data = await apiRes.json();
      setThreads(
        data
          ?.filter(({ parent_id }) => selectedAnimeKey === parent_id)
          ?.sort(
            (a, b) =>
              new Date(b?.created_at).getTime() -
              new Date(a?.created_at).getTime()
          )
      );
      setIsLoading(false);
    })();
  }, [location]);

  const handleShowCreateForm = () =>
    navigate(`/community/${selectedAnimeKey}/create`);

  const handleCreateThread = async () => {
    if (isPosting) return;
    if (title?.length === 0) return;
    try {
      setIsPosting(true);
      await fetch("https://api.be-native.life/api/travel/favthread", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          parent_id: selectedAnimeKey,
          creator_user_id: localStorage.getItem("USER_ID"),
          title,
          tag: selectedAnimeKey,
          param1: description,
          param2: localStorage.getItem("USER_NAME"),
          param3: localStorage.getItem("USER_QR_COLOR"),
          param4: localStorage.getItem("USER_NATIVE_TYPE"),
          param5: "string",
          param6: "string",
          param7: "string",
        }),
      });
      navigate(`/community/${selectedAnimeKey}`);
    } catch (_) {
    } finally {
      setIsPosting(false);
    }
  };

  const handleThreadClick = (id) => {
    navigate(`/community/${selectedAnimeKey}/${id}`);
  };

  useEffect(() => {
    (async () => {
      if (!threadId) return;
      const currentThreadApiRes = await fetch(
        `https://api.be-native.life/api/travel/favthread/${threadId}`
      );
      const currentThreadData = await currentThreadApiRes.json();
      setCurrentThread(currentThreadData);
      const apiRes = await fetch(
        `https://api.be-native.life/api/travel/favthread/${threadId}/messages`
      );
      const data = await apiRes.json();
      setCurrentThreadMessages(
        data?.sort(
          (a, b) =>
            new Date(b?.created_at).getTime() -
            new Date(a?.created_at).getTime()
        )
      );
    })();
  }, [threadId, location, triggerAPI]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setCurrentThread(null);
    setCurrentThreadMessages([]);
    setPostMessage("");
  }, [threadId, location]);

  const handlePostMessage = async () => {
    if (isPosting) return;
    if (postMessage?.length === 0) return;
    try {
      setIsPosting(true);
      await fetch("https://api.be-native.life/api/travel/favthread/messages", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          thread_id: threadId,
          creator_user_id: localStorage.getItem("USER_ID"),
          message: postMessage,
          param1: description,
          param2: localStorage.getItem("USER_NAME"),
          param3: localStorage.getItem("USER_QR_COLOR"),
          param4: localStorage.getItem("USER_NATIVE_TYPE"),
          param5: "string",
          param6: "string",
          param7: "string",
        }),
      });
      setPostMessage("");
      setTriggerAPI((p) => !p);
    } catch (_) {
    } finally {
      setIsPosting(false);
    }
  };

  const handleLikeMessage = async (messageId) => {
    if (isLiking) return;
    setIsLiking(true);
    try {
      await fetch(
        `https://api.be-native.life/api/travel/favthread/messages/${messageId}/likes?user_id=${localStorage.getItem(
          "USER_ID"
        )}`,
        {
          method: "POST",
        }
      );
      setTriggerAPI((p) => !p);
    } catch (_) {
    } finally {
      setIsLiking(false);
    }
  };

  return (
    <div>
      <div className="w-full flex justify-between mt-5">
        <div className="flex flex-col gap-3">
          <p className="font-semibold text-[16px] text-[#414853]">
            <RenderTranslatedText
              i8nKey={"community_selected_fav_card_title"}
            />
          </p>
          <p className="text-[12px] text-[#555E6C]">
            <RenderTranslatedText
              i8nKey={"community_selected_fav_card_subtitle"}
            />
          </p>
        </div>
        <div className="flex flex-col items-center gap-3 w-[120px]">
          <img
            src={selectedAnimeConfig?.cover}
            className="aspect-square object-fill w-full"
          />
          <p className="text-[14px] text-[#555E6C] uppercase text-center">
            <RenderTranslatedText i8nKey={selectedAnimeConfig?.titleKey} />
          </p>
        </div>
      </div>
      {isLoading ? (
        <div className="w-full flex items-center justify-center text-[16px] font-bold h-[350px]">
          <RenderTranslatedText i8nKey={"loading_text"} />
        </div>
      ) : isThreadView ? (
        <div>
          <div className="flex items-center my-5 w-full justify-between gap-3">
            <input
              className="w-full text-[14px] px-5 py-2 rounded-full"
              onChange={(e) => setPostMessage(e.target.value)}
              value={postMessage}
              placeholder={t(
                "community_post_message_to_thread_input_placeholder"
              )}
            />
            <button
              className="text-white text-[14px] text-bold py-2 px-5 rounded-full"
              style={{
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                background: "linear-gradient(0deg, #EC736E, #EC736E)",
              }}
              onClick={handlePostMessage}
            >
              <p className="break-keep">
                <RenderTranslatedText
                  i8nKey={"community_post_message_to_thread_cta"}
                />
              </p>
            </button>
          </div>
          <div
            className="w-full grid bg-white p-5 rounded-lg gap-3 mt-10"
            style={{ gridTemplateColumns: "1fr 7fr" }}
          >
            <div>
              <img
                className="aspect-square object-contain"
                src={`https://api.be-native.life/api/friends/charimg/${currentThread?.param4}`}
              />
            </div>
            <div>
              <p className="font-semibold text-[#414853] text-[14px]">
                {currentThread?.param2}
              </p>
              <p className="text-[#808691] text-[14px]">
                {currentThread?.title}
              </p>
              <p className="text-[#808691] text-[12px]">
                {currentThread?.param1}
              </p>
            </div>
          </div>
          <div className="flex flex-col items-end w-full">
            <div className="w-[90%]">
              {currentThreadMessages?.map(
                ({
                  param4,
                  message,
                  param2,
                  num_liked,
                  message_id,
                  liked = false,
                }) => {
                  return (
                    <div className="my-5">
                      <div
                        className="w-full grid bg-white p-5 rounded-lg gap-3 rounded-bl-none"
                        style={{ gridTemplateColumns: "1fr 7fr" }}
                      >
                        <div>
                          <img
                            className="aspect-square object-contain"
                            src={`https://api.be-native.life/api/friends/charimg/${param4}`}
                          />
                        </div>
                        <div>
                          <p className="font-semibold text-[#414853] text-[14px]">
                            {param2}
                          </p>
                          <p className="text-[#808691] text-[12px]">
                            {message}
                          </p>
                        </div>
                      </div>
                      <div className="bg-white w-min py-1.5 px-3 rounded-b-lg flex items-center gap-2">
                        {liked ? (
                          <div className="flex flex-row items-center gap-1">
                            <img
                              src={heartFilled}
                              className="min-w-[18px] object-contain"
                            />
                            <p className="text-[12px] text-[#424242]">
                              {num_liked}
                            </p>
                          </div>
                        ) : (
                          <div
                            className="flex flex-row items-center gap-1"
                            onClick={() => handleLikeMessage(message_id)}
                          >
                            <img
                              src={heartOutline}
                              className="min-w-[18px] object-contain"
                            />
                            <p className="text-[12px] text-[#424242]">
                              {num_liked}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      ) : isCreate ? (
        <div>
          <input
            className="bg-white rounded-lg w-full my-5 p-3"
            placeholder={t(
              "community_selected_fav_card_create_thread_placeholder_title"
            )}
            onChange={(e) => setTitle(e.target.value)}
          />
          <textarea
            className="bg-white rounded-lg w-full resize-none p-3"
            placeholder={t(
              "community_selected_fav_card_create_thread_placeholder_description"
            )}
            rows={5}
            onChange={(e) => setDescription(e.target.value)}
          />
          <button
            onClick={handleCreateThread}
            className=" border-none w-full mx-auto h-[60px] flex items-center justify-center rounded-lg my-5"
            style={{
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              background: "linear-gradient(0deg, #EC736E, #EC736E)",
            }}
          >
            <p className="text-[20px] text-white font-bold">
              <RenderTranslatedText
                i8nKey={"community_selected_fav_card_post_thread_cta"}
              />
            </p>
          </button>
        </div>
      ) : (
        <div>
          <button
            onClick={handleShowCreateForm}
            className=" border-none w-full mx-auto h-[60px] flex items-center justify-center rounded-lg my-5"
            style={{
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              background: "linear-gradient(0deg, #EC736E, #EC736E)",
            }}
          >
            <p className="text-[20px] text-white font-bold">
              <RenderTranslatedText
                i8nKey={"community_selected_fav_card_create_thread_cta"}
              />
            </p>
          </button>
          <div className="my-10">
            {threads?.map(
              ({
                param4,
                title,
                param2,
                num_messages,
                thread_id,
                num_likes,
              }) => {
                return (
                  <div
                    className="my-5"
                    onClick={() => handleThreadClick(thread_id)}
                  >
                    <div
                      className="w-full grid bg-white p-5 rounded-lg gap-3 rounded-bl-none"
                      style={{ gridTemplateColumns: "1fr 7fr" }}
                    >
                      <div>
                        <img
                          className="aspect-square object-contain"
                          src={`https://api.be-native.life/api/friends/charimg/${param4}`}
                        />
                      </div>
                      <div>
                        <p className="font-semibold text-[#414853] text-[14px]">
                          {param2}
                        </p>
                        <p className="text-[#808691] text-[12px]">{title}</p>
                      </div>
                    </div>
                    <div className="bg-white w-min py-1.5 px-3 rounded-b-lg flex items-center gap-2">
                      <div className="flex flex-row items-center gap-1">
                        <img
                          src={heartOutline}
                          className="min-w-[18px] object-contain"
                        />
                        <p className="text-[12px] text-[#424242]">
                          {num_likes}
                        </p>
                      </div>
                      <div className="flex flex-row items-center gap-1">
                        <img
                          src={commentIcon}
                          className="min-w-[18px] object-contain"
                        />
                        <p className="text-[12px] text-[#424242]">
                          {num_messages}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      )}
    </div>
  );
}
