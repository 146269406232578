import { Link, useNavigate } from "react-router-dom";
import RenderTranslatedText from "../../../components/RenderTranslatedText";
import { getFileUrl } from "../../../components/ImageUpload/constants";

export default function CharactersRow({ title, characters = [], catKey }) {
  const navigate = useNavigate();

  const handleSeeAll = () => {
    navigate(`/characters/${catKey}/${title}`);
  };

  return (
    <div className="w-full my-10">
      <div className="w-full flex items-center justify-between">
        <p className="otaDark text-[16px] font-bold">{title}</p>
        <p
          className="otaFont underline text-[14px] font-semibold"
          onClick={handleSeeAll}
        >
          <RenderTranslatedText i8nKey={"characters_create_page_see_all"} />
        </p>
      </div>
      {characters?.length > 0 ? (
        <div className="w-full flex flex-row overflow-scroll gap-5 mt-5 pb-3">
          {characters?.map(({ picture, nickname, char_id }) => {
            return (
              <Link to={`/characters/${char_id}`} key={char_id}>
                <div className="max-w-[120px] min-w-[120px] flex flex-col gap-3 items-center">
                  <img
                    src={getFileUrl(picture)}
                    className="aspect-square object-cover object-center rounded-3xl w-full overflow-hidden"
                    style={{ border: "1px solid #D0D5DD" }}
                  />
                  <p className="otaLight text-[12px]">{nickname}</p>
                </div>
              </Link>
            );
          })}
        </div>
      ) : (
        <div className="flex items-center justify-center h-[150px]">
          <p className="otaLight">
            <RenderTranslatedText
              i8nKey={"characters_page_no_characters_fallback"}
            />
          </p>
        </div>
      )}
    </div>
  ); 
}
