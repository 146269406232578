import { logo } from "../../assets/brand";
import { useEffect, useState } from "react";
//@ts-ignore
import { CircleProgress } from "react-gradient-progress";
import ShareSection from "../../components/ShareSection";
import {
  CoupleOnScooter,
  ManPondering,
  ManWomanTable,
  MapPin,
  PeopleConversation,
} from "../../assets/illustrations";
import { SectionText, WhiteCard } from "../../components/PageCommons";
import { useNavigate, useParams } from "react-router-dom";
import { GoldenBanner, GoldenBannerEnglish } from "../../assets";
import useResetScroll from "../../utils/useResetScroll";
import { useTranslation } from "react-i18next";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import NewTagMatchedModal from "./components/NewTagMatchedModal";
import OtaGradientButton from "../../components/OtaGradientButton";
import { backButton } from "../../assets/icons";

function addAlpha(color, opacity) {
  var _opacity = Math.round(Math.min(Math.max(opacity ?? 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

const SectionTitleBlack = ({ children }) => (
  <p className="text-black text-center font-semibold text-[16px] my-2">
    {children}
  </p>
);

export default function Compatibility({
  isSingleSelfMatchingType = false,
  isDoubleSelfMatchingType = false,
  isCharacterType = false,
}) {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  useResetScroll();
  const navigate = useNavigate();
  const {
    userId,
    referrerId,
    name,
    year,
    month,
    day,
    name2,
    year2,
    month2,
    day2,
    charId,
  } = useParams();
  const { referrerId: referrerIDFromURL } = useParams();
  const [selfData, setSelfData] = useState();
  const [referrerData, setReferrerData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [compatibilityData, setCompatibilityData] = useState();
  const [referrerDateObj, setReferrerDateObj] = useState({});
  const [selfUsername, setSelfUsername] = useState();
  const [referrerUsername, setReferrerUserName] = useState();
  const [isNewTagMatched, setIsNewTagMatched] = useState(true);

  const userIdFromLocalStorage = localStorage.getItem("USER_ID");
  const isUserAuthenticated =
    !!userIdFromLocalStorage && userIdFromLocalStorage?.length > 0;

  const fetchData = async () => {
    setIsLoading(true);

    const userInfoAPIRes = await fetch(
      ` https://api.be-native.life/api/users/${userId}?lang=${language}`
    );
    const userInfo = await userInfoAPIRes.json();

    const selfDate = isDoubleSelfMatchingType
      ? day
      : userInfo?.dob?.split("-")?.[2];
    const selfMonth = isDoubleSelfMatchingType
      ? month
      : userInfo?.dob?.split("-")?.[1];
    const selfYear = isDoubleSelfMatchingType
      ? year
      : userInfo?.dob?.split("-")?.[0];
    setSelfUsername(isDoubleSelfMatchingType ? name : userInfo?.input_name);

    const referrerInfoAPIRes = await fetch(
      ` https://api.be-native.life/api/users/${referrerId}?lang=${language}`
    );
    const referrerInfo = await referrerInfoAPIRes.json();

    const referrerDate = isDoubleSelfMatchingType
      ? day2
      : isSingleSelfMatchingType
      ? day
      : referrerInfo?.dob?.split("-")?.[2];
    const referrerMonth = isDoubleSelfMatchingType
      ? month2
      : isSingleSelfMatchingType
      ? month
      : referrerInfo?.dob?.split("-")?.[1];
    const referrerYear = isDoubleSelfMatchingType
      ? year2
      : isSingleSelfMatchingType
      ? year
      : referrerInfo?.dob?.split("-")?.[0];
    setReferrerUserName(
      decodeURIComponent(
        isDoubleSelfMatchingType
          ? name2
          : isSingleSelfMatchingType
          ? name
          : referrerInfo?.input_name
      )
    );

    setReferrerDateObj({
      day: referrerDate,
      month: referrerMonth,
      year: referrerYear,
    });

    const apiResponseSelf = await fetch(
      ` https://api.be-native.life/api/friends/friends_data/${selfYear}/${selfMonth}/${selfDate}?lang=${language}`
    );
    const apiDataSelf = await apiResponseSelf.json();
    const apiResponseReferrer = await fetch(
      ` https://api.be-native.life/api/friends/friends_data/${referrerYear}/${referrerMonth}/${referrerDate}?lang=${language}`
    );
    const apiDataReferrer = await apiResponseReferrer.json();
    const apiResponseCompatibility = await fetch(
      ` https://api.be-native.life/api/friends/matchin_data/${referrerYear}/${referrerMonth}/${referrerDate}/and/${selfYear}/${selfMonth}/${selfDate}?lang=${language}`
    );
    const scoreMatchRes = await fetch(
      ` https://api.be-native.life/api/friends/score/user_ids/${localStorage.getItem(
        "USER_ID"
      )}/${
        referrerIDFromURL ?? localStorage.getItem("REFERRER_ID")
      }?lang=${language}`,
      { method: "POST" }
    );
    if (isCharacterType && charId && userId) {
      await fetch(
        `https://api.be-native.life/api/character/match?char_id=${charId}&creator_user_id=${userId}`,
        {
          method: "POST",
        }
      );
    }
    const scoreMatchData = await scoreMatchRes.json();
    setIsNewTagMatched(scoreMatchData?.is_new_tag);
    const apiDataCompatibility = await apiResponseCompatibility.json();
    setCompatibilityData(apiDataCompatibility);
    setSelfData(apiDataSelf);
    setReferrerData(apiDataReferrer);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [language]);

  const selfColor = selfData?.hex_color;
  const referrerColor = referrerData?.hex_color;

  const selfTitle = selfData?.param30;
  const referrerTitle = referrerData?.param30;

  const selfOneLiner = selfData?.param8;
  const referrerOneLiner = referrerData?.param8;

  const selfType = selfData?.param7;
  const referrerType = referrerData?.param7;

  const compatibilityText = compatibilityData?.text_data;

  const selfCard1Text = selfData?.param31;
  const referrerCard1Text = referrerData?.param31;

  const selfCard2Text = selfData?.param32;
  const referrerCard2Text = referrerData?.param32;

  const selfCard3Text = selfData?.param33;
  const referrerCard3Text = referrerData?.param33;

  const selfCard4Text = selfData?.param34;
  const referrerCard4Text = referrerData?.param34;

  const selfCard5Text = selfData?.param35;
  const referrerCard5Text = referrerData?.param35;

  const referrerDescription = referrerData?.param9;
  const referrerHashtags = referrerData?.param10;

  const showGoldenBanner =
    compatibilityData?.score === 120 ||
    compatibilityData?.score === 100 ||
    compatibilityData?.score === 150;

  const shareLink = `${window.location.href}?referrer-id=${userIdFromLocalStorage}&openExternalBrowser=1`;

  const handleMatchedPage = () => {
    navigate("/matched");
  };

  const handleSelfCompatibilityPage = () => {
    navigate(isUserAuthenticated ? "/self-matching" : "/");
  };

  const handleRightUserNativeCard = () => {
    navigate(
      isDoubleSelfMatchingType || isSingleSelfMatchingType
        ? `/native-card/${encodeURIComponent(referrerUsername)}/${
            referrerDateObj?.year
          }/${referrerDateObj?.month}/${referrerDateObj?.day}`
        : `/native-card?referrer-id=${referrerIDFromURL}&openExternalBrowser=1&skipMatchingPageNavigation=true&hideMatchingButton=true`
    );
  };

  const handleCloseNewTagModal = () => setIsNewTagMatched(false);

  const handleBack = () => {
    navigate(-1);
  };

  return isLoading ? (
    <div className="min-h-[100svh] w-full flex flex-col items-center justify-center gap-2">
      <p className="text-[24px] font-bold text-neutral-800">
        <RenderTranslatedText i8nKey={"loading_text"} />
      </p>
    </div>
  ) : (
    <>
      {isNewTagMatched ? (
        <NewTagMatchedModal
          title={referrerOneLiner}
          color={referrerColor}
          description={referrerDescription}
          hashtags={referrerHashtags}
          characterSrc={`https://api.be-native.life/api/friends/charimg/${referrerType}`}
          tag={referrerType}
          handleClose={handleCloseNewTagModal}
        />
      ) : null}
      <div className="mx-auto max-w-[450px] w-full flex flex-col items-center gap-10 min-h-[100svh] pb-10">
        {showGoldenBanner ? (
          <div className="relative">
            <img
              src={language === "en" ? GoldenBannerEnglish : GoldenBanner}
              className="w-[380px] object-contain"
            />
            <p className="absolute top-[140px] left-[50%] px-5 translate-x-[-65%] text-[36px] font-extrabold text-[#FF8D8D]">
              {compatibilityData?.score}
            </p>
            <div className="flex w-full justify-center absolute top-[260px]">
              <img
                alt=""
                src={`https://api.be-native.life/api/friends/charimg/${selfType}`}
                className="w-[150px]"
                style={{ zIndex: 1 }}
              />
              <img
                alt=""
                src={`https://api.be-native.life/api/friends/charimg/${referrerType}`}
                className="w-[150px]"
                style={{ zIndex: 1 }}
              />
            </div>
          </div>
        ) : null}
        <div
          className="w-[95%] mx-auto flex flex-col gap-4 bg-white rounded-br-[20px] rounded-bl-[20px] min-h-[460px] overflow-hidden relative"
          style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
        >
          <img
            src={backButton}
            className="w-[32px] mt-5 ml-5"
            onClick={handleBack}
          />
          <div className="w-full flex flex-col items-center gap-5 mb-2 mt-[-16px]">
            <div className="flex flex-row items-center gap-2 justify-center">
              <div className="bg-black h-[1px] w-10" />
              <p className="text-black text-[24px] font-semibold text-center">
                <RenderTranslatedText i8nKey={"compatibility_page_title"} />
              </p>
              <div className="bg-black h-[1px] w-10" />
            </div>
          </div>
          {/* Section One */}
          <div className="w-full grid grid-cols-2 relative">
            <div
              className="absolute top-[50%] left-[50%] w-[110px]"
              style={{ transform: "translate(-68%,-40%)" }}
            >
              {!showGoldenBanner ? (
                <>
                  <CircleProgress
                    percentage={
                      compatibilityData?.score > 100
                        ? 100
                        : compatibilityData?.score
                    }
                    strokeWidth={14}
                    width={150}
                    primaryColor={["#B69EC6", "#A6C1EA"]}
                    secondaryColor="#E5E5E5"
                  />
                  <div
                    style={{ fontSize: 12 }}
                    className="bg-white w-[82px] h-[82px] rounded-full flex flex-col items-center justify-center absolute top-[35px] left-[34px]"
                  >
                    <p className="text-black font-semibold text-center text-[8px] w-[40px]">
                      <RenderTranslatedText
                        i8nKey={"compatibility_page_progress_bar_text"}
                      />
                    </p>
                    <p className="text-center text-[#FF8D8D] text-[22px] font-extrabold">
                      {compatibilityData?.score}%
                    </p>
                  </div>
                  <p className="absolute top-[136px] text-[13px] left-[42px] font-semibold whitespace-nowrap">
                    <RenderTranslatedText
                      i8nKey={"compatibility_page_progress_lower_text"}
                    />
                  </p>
                </>
              ) : (
                <>
                  <CircleProgress
                    percentage={100}
                    strokeWidth={14}
                    width={150}
                    primaryColor={["#EAC761", "#E6B422", "#806413"]}
                    secondaryColor="#E5E5E5"
                  />
                  <div
                    style={{ fontSize: 12 }}
                    className="bg-white w-[82px] h-[82px] rounded-full flex flex-col items-center justify-center absolute top-[35px] left-[34px]"
                  >
                    <p className="text-black font-semibold text-center text-[8px] w-[40px]">
                      ふたりの
                      <br />
                      フィット感
                    </p>
                    <p className="text-center text-[#FF8D8D] text-[22px] font-extrabold">
                      神
                    </p>
                  </div>
                </>
              )}
            </div>
            <div
              className="w-full flex flex-col gap-3 items-center px-5 pb-5"
              style={{
                borderTop: `8px solid ${selfColor}`,
                background: addAlpha(selfColor, 0.2),
              }}
            >
              <p className="text-black text-center font-semibold text-[16px] mt-2 break-words">
                {t("compatibility_page_name_title", { name: selfUsername })}
              </p>
              <p
                className="text-[14px] font-semibold h-[60px]"
                style={{ color: selfColor }}
              >
                {selfTitle}
              </p>
              <div className="w-full flex flex-col items-start">
                {selfType && (
                  <img
                    alt=""
                    src={`https://api.be-native.life/api/friends/charimg/${selfType}`}
                    className="w-[120px] h-[120px]"
                    style={{ zIndex: 1 }}
                  />
                )}
              </div>
              <p className="text-[14px] text-center font-semibold text-black w-[80%] mx-auto">
                {selfOneLiner}
              </p>
              <p
                className="text-[36px] text-center font-semibold"
                style={{ color: `${selfColor}` }}
              >
                {selfType}
              </p>
            </div>
            <div
              className="w-full flex flex-col gap-3 items-center px-5 pb-5"
              style={{
                borderTop: `8px solid ${referrerColor}`,
                background: addAlpha(referrerColor, 0.2),
              }}
            >
              <p
                className="text-black text-center font-semibold text-[16px] mt-2"
                dangerouslySetInnerHTML={{
                  __html: t("compatibility_page_name_title", {
                    name: referrerUsername,
                  }),
                }}
              />
              <p
                className="text-[14px] font-semibold  h-[60px] break-words"
                style={{ color: referrerColor }}
              >
                {referrerTitle}
              </p>
              <div className="w-full flex flex-col items-end">
                {referrerType && (
                  <img
                    alt=""
                    src={`https://api.be-native.life/api/friends/charimg/${referrerType}`}
                    className="w-[120px] h-[120px]"
                    style={{ zIndex: 1 }}
                  />
                )}
              </div>
              <p className="text-[14px] text-center font-semibold text-black w-[80%] mx-auto">
                {referrerOneLiner}
              </p>
              <p
                className="text-[36px] text-center font-semibold "
                style={{ color: `${referrerColor}` }}
              >
                {referrerType}
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center mx-auto w-[80%] mb-5 mt-2">
            <SectionText thin>{compatibilityText}</SectionText>
          </div>
        </div>

        <ShareSection
          shareLink={`${window.location.href}?openExternalBrowser=1`}
          isCompatibility
          customShareTitle={
            "次のMBTI！生年月日入力のみ。 1秒で推し、友達との相性診断ができる｜1秒友達相性診断サービス「native. for Friends」"
          }
        />

        <p className="text-[#FF8D8D] text-[18px] font-semibold">
          <RenderTranslatedText
            i8nKey={"compatibility_page_sections_top_title"}
          />
        </p>
        {/* Card One */}
        <WhiteCard noPadding>
          <div className="h-1" />
          <SectionTitleBlack>
            1. {t("compatibility_page_communication_style_heading")}
          </SectionTitleBlack>
          <div className="mx-auto">
            <img
              alt=""
              src={PeopleConversation}
              className="w-[180px] h-[120px]"
            />
          </div>
          <div className="grid grid-cols-2">
            <div
              className="w-full flex flex-col gap-3 items-center px-5 pt-5 pb-5"
              style={{
                background: addAlpha(selfColor, 0.2),
                borderRight: "0.5px solid black",
              }}
            >
              <SectionText>{selfCard1Text}</SectionText>
            </div>
            <div
              className="w-full flex flex-col gap-3 items-center px-5 pt-5 pb-5"
              style={{
                background: addAlpha(referrerColor, 0.2),
              }}
            >
              <SectionText>{referrerCard1Text}</SectionText>
            </div>
          </div>
        </WhiteCard>

        {/* Card Two */}
        <WhiteCard noPadding>
          <div className="h-1" />
          <SectionTitleBlack>
            <p>2. {t("compatibility_page_upset_trigger_heading")}</p>
          </SectionTitleBlack>
          <div className="mx-auto my-5">
            <img alt="" src={ManPondering} className="w-[150px] h-[120px]" />
          </div>
          <div className="grid grid-cols-2">
            <div
              className="w-full flex flex-col gap-3 items-center px-5 pt-5 pb-5"
              style={{
                background: addAlpha(selfColor, 0.2),
                borderRight: "0.5px solid black",
              }}
            >
              <SectionText>{selfCard2Text}</SectionText>
            </div>
            <div
              className="w-full flex flex-col gap-3 items-center px-5 pt-5 pb-5"
              style={{
                background: addAlpha(referrerColor, 0.2),
              }}
            >
              <SectionText>{referrerCard2Text}</SectionText>
            </div>
          </div>
        </WhiteCard>

        {/* Card Three */}
        <WhiteCard noPadding>
          <div className="h-1" />
          <SectionTitleBlack>
            3. {t("compatibility_page_activities_heading")}
          </SectionTitleBlack>
          <div className="mx-auto">
            <img alt="" src={CoupleOnScooter} className="w-[150px] h-[120px]" />
          </div>
          <div className="grid grid-cols-2">
            <div
              className="w-full flex flex-col gap-3 items-center px-5 pt-5 pb-5"
              style={{
                background: addAlpha(selfColor, 0.2),
                borderRight: "0.5px solid black",
              }}
            >
              <SectionText>{selfCard3Text}</SectionText>
            </div>
            <div
              className="w-full flex flex-col gap-3 items-center px-5 pt-5 pb-5"
              style={{
                background: addAlpha(referrerColor, 0.2),
              }}
            >
              <SectionText>{referrerCard3Text}</SectionText>
            </div>
          </div>
        </WhiteCard>

        {/* Card Four */}
        <WhiteCard noPadding>
          <div className="h-1" />
          <SectionTitleBlack>
            4. {t("compatibility_page_actions_to_deepen_friendship_heading")}
          </SectionTitleBlack>
          <div className="mx-auto">
            <img alt="" src={ManWomanTable} className="w-[150px] h-[120px]" />
          </div>
          <div className="grid grid-cols-2">
            <div
              className="w-full flex flex-col gap-3 items-center px-5 pt-5 pb-5"
              style={{
                background: addAlpha(selfColor, 0.2),
                borderRight: "0.5px solid black",
              }}
            >
              <SectionText>{selfCard4Text}</SectionText>
            </div>
            <div
              className="w-full flex flex-col gap-3 items-center px-5 pt-5 pb-5"
              style={{
                background: addAlpha(referrerColor, 0.2),
              }}
            >
              <SectionText>{referrerCard4Text}</SectionText>
            </div>
          </div>
        </WhiteCard>

        {/* Card Five */}
        <WhiteCard noPadding>
          <div className="h-1" />
          <SectionTitleBlack>
            5. {t("compatibility_page_destinations_heading")}
          </SectionTitleBlack>
          <div className="mx-auto">
            <img alt="" src={MapPin} className="w-[150px] h-[120px]" />
          </div>
          <div className="grid grid-cols-2">
            <div
              className="w-full flex flex-col gap-3 items-center px-5 pt-5 pb-5"
              style={{
                background: addAlpha(selfColor, 0.2),
                borderRight: "0.5px solid black",
              }}
            >
              <SectionText>{selfCard5Text}</SectionText>
            </div>
            <div
              className="w-full flex flex-col gap-3 items-center px-5 pt-5 pb-5"
              style={{
                background: addAlpha(referrerColor, 0.2),
              }}
            >
              <SectionText>{referrerCard5Text}</SectionText>
            </div>
          </div>
        </WhiteCard>

        <button
          onClick={handleRightUserNativeCard}
          className="border-none w-[85%] mx-auto h-[70px] flex items-center justify-center rounded-[20px] gap-3"
          style={{
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            background: referrerColor,
          }}
        >
          <p
            className="text-white text-[16px] font-semibold"
            dangerouslySetInnerHTML={{
              __html: t("compatibility_page_partner_native_card_CTA", {
                name: referrerUsername,
              }),
            }}
          />
        </button>

        {isCharacterType ? (
          <OtaGradientButton
            onClick={handleRightUserNativeCard}
            className="border-none max-w-[400px] w-[90%] mx-auto min-h-[48px] px-8 flex items-center justify-center rounded-[12px] gap-3 fixed translate-x-[-50%] left-[50%] bottom-24 z-50 py-2"
            style={{
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              background: referrerColor,
              zIndex: 999,
            }}
          >
            <p
              className="text-white text-[16px] font-semibold"
              dangerouslySetInnerHTML={{
                __html: t("compatibility_page_partner_native_card_CTA", {
                  name: referrerUsername,
                }),
              }}
            />
          </OtaGradientButton>
        ) : null}

        {isUserAuthenticated ? (
          <ShareSection
            name={`${selfUsername}`}
            shareLink={shareLink}
            mainColor={selfColor}
            showQR
          />
        ) : null}

        <button
          onClick={handleSelfCompatibilityPage}
          className="border-none w-[85%] mx-auto h-[70px] flex items-center justify-center bg-[#EC736E] rounded-[20px] gap-3"
          style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
        >
          <p className="text-white text-[14px] font-semibold">
            {t("self_matching_CTA")}
          </p>
        </button>

        {isUserAuthenticated ? (
          <button
            onClick={handleMatchedPage}
            className="mt-[-16px] border-none w-[85%] mx-auto h-[70px] flex items-center justify-center bg-[#222222] rounded-[20px] gap-3"
            style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
          >
            <p className="text-white text-[16px] font-semibold">
              {t("native_card_compatibility_result_list_CTA")}
            </p>
          </button>
        ) : null}

        {/* Footer */}
        <div className="flex flex-col gap-5 items-center">
          <div className="flex flex-col items-center mt-2 mb-1">
            <img
              alt=""
              src={logo}
              style={{ zIndex: 1 }}
              className="w-[150px]"
            />
          </div>
          <p className="text-center text-[8px] text-[#A1A1AA]">
            © 2024 native.K.K All Rights Reserved.
          </p>
        </div>
      </div>
    </>
  );
}
